
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import { FetchData, Root, Store } from '@/main';
@Component({
  components: {
    ValidationSummary,
  },
})
class AddProgramWithEntity extends Vue {
  @Prop() private allPrograms?: ProgramItem[];
  @Prop() private selectedEntityId?: number;
  @Prop() private isBulkOperation?: boolean;
  @Prop() private selectedItems?: number[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  @Prop() private entityType?: number;

  private localAllPrograms?: ProgramItem[] = [];
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private overwriteLinkedProgram = false;
  private isMobileView = false;
  private program = 'Program';
  private isLinkedItem = false;

  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
    Root,
    Store,
    this.$t,
  );
  // function to move scroll to the top
  private scrollToTop() {
    setTimeout(() => {
      const el = document.getElementById('dvAddPrograms');
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      }
    }, 200);
  }
  // Mounted lifecycle event of Component
  private mounted() {
    // Set listing object for Information
    if (this.allPrograms != null) {
      this.localAllPrograms = this.allPrograms;
    }
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.visibleValidationSummary = false;
    if (this.isBulkOperation) {
      this.UncheckAllPrograms();
    }

    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (!this.isMobileView) {
      this.scrollToTop();
    }
    if (this.entityType === ModuleEnum.Dialogue) {
      Root.emit('notification-hide-edit-item-buttons', false);
    }
    const linkedItem  = this.localAllPrograms!.find((a)=> a.IsLinked === true);
    if (this.entityType === ModuleEnum.Dialogue &&  linkedItem !== null && linkedItem !== undefined && linkedItem.Id > 0) {
      this.isLinkedItem = true;
    }
  }

  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Close Add clickOnBackCustomCategoryEdit Section
  private clickOnBackCustomCategoryEdit() {
    this.$emit('selectBack', {
      showAddPrograms: false,
      programs: this.localAllPrograms,
    });
    setTimeout(() => {
      Root.emit('customCategoryDetailLoadingVod', false);
    }, 500);
  }


  private addItem(item: ProgramItem) {
    if (item !== null && item !== undefined) {
      if (this.entityType! === ModuleEnum.Information) {
        this.addItemToInformation(item);
      } else {
        this.SaveItemToDialogue(item);
      }
    }
  }

  // Add a new Linked Information to program Item
  private addItemToInformation(item: ProgramItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllPrograms!.findIndex(
        (obj: ProgramItem) =>
          obj.Id === item.Id,
      );
      if (this.selectedEntityId! > 0 && item.Id > 0) {
        const informationIds: number[] = [];
        informationIds.push(this.selectedEntityId!);
        const programIds: number[] = [];
        programIds.push(item.Id);
        item.IsLinked = true;
        FetchData('/CustomCategory/AddLinkToProgram', JSON.stringify({
          customCategoryIds: informationIds,
          programIds,
        }))
          .then((response) => {
            if (response.success === false) {
              alert(response.message);
              item.IsLinked = false;
            } else {
              if(this.localAllPrograms !== null && this.localAllPrograms !== undefined) {
                this.localAllPrograms[objIndex] = item;
              }
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllPrograms,
                programDetailType: 'LinkedProgram',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = true;
    }
  }


  // Add a new Linked Information to program Item
  private addItemToDialogue(item: ProgramItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllPrograms!.findIndex(
        (obj: ProgramItem) =>
          obj.Id === item.Id,
      );
      if (this.selectedEntityId! > 0 && item.Id > 0) {
        const informationIds: number[] = [];
        informationIds.push(this.selectedEntityId!);
        const programIds: number[] = [];
        programIds.push(item.Id);
        item.IsLinked = true;
        FetchData('/Notification/SaveOrUpdateLinkedEntityWithDialogue', JSON.stringify({
          notificationId: this.selectedEntityId,
          entityId: item.Id,
          entityType: this.program,
        }))
          .then((response) => {
            if (response.success === false) {
              alert(response.message);
              item.IsLinked = false;
            } else {
              if(this.localAllPrograms !== null && this.localAllPrograms !== undefined) {
                this.localAllPrograms[objIndex] = item;
              }
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllPrograms,
                programDetailType: 'LinkedProgram',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
              this.isLinkedItem = true;
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = true;
    }
  }



  private UncheckAllPrograms() {
    this.localAllPrograms!.forEach((element) => {
      element.IsLinked = false;
    });
  }

  private removeItem(item: ProgramItem) {
    if (item !== null && item !== undefined) {
      if (this.entityType! === ModuleEnum.Information) {
        this.removeItemToProgram(item);
      } else {
        this.CancelItemToDialogue(item);
      }
    }
  }
  private SaveItemToDialogue(item: ProgramItem) {
    item.IsLinked = true;
    this.isLinkedItem = true;
  }

  private CancelItemToDialogue(item: ProgramItem) {
    item.IsLinked = false;
    this.isLinkedItem = false;
  }

  // Remove a Linked Information from  program Item
  private removeItemToProgram(item: ProgramItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllPrograms!.findIndex(
        (obj: ProgramItem) =>
          obj.Id === item.Id,
      );

      if (this.selectedEntityId! > 0 && item.Id > 0) {
        const informationIds: number[] = [];
        informationIds.push(this.selectedEntityId!);
        const programIds: number[] = [];
        programIds.push(item.Id);
        item.IsLinked = false;
        FetchData('/CustomCategory/RemoveLinkToProgram', JSON.stringify({
          customCategoryIds: informationIds,
          programIds,
        }))
          .then((response) => {
            if (response.success === false) {
              alert(response.message);
              item.IsLinked = true;
            } else {
              if(this.localAllPrograms !== null && this.localAllPrograms !== undefined) {
                this.localAllPrograms[objIndex] = item;
              }
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllPrograms,
                programDetailType: 'LinkedProgram',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
            }
          })
          .catch(() => {
            // Log error if required
          });
      }
    } else {
      item.IsLinked = false;
    }
  }


  private removeItemToDialogue(item: ProgramItem) {
    if (!this.isBulkOperation) {
      const objIndex = this.localAllPrograms!.findIndex(
        (obj: ProgramItem) =>
          obj.Id === item.Id,
      );

      if (this.selectedEntityId! > 0 && item.Id > 0) {
        const informationIds: number[] = [];
        informationIds.push(this.selectedEntityId!);
        const programIds: number[] = [];
        programIds.push(item.Id);
        item.IsLinked = false;
        FetchData('/Notification/SaveOrUpdateLinkedEntityWithDialogue', JSON.stringify({
          notificationId: this.selectedEntityId,
          entityId: 0,
          entityType: this.program,
        }))
          .then((response) => {
            if (response.success === false) {
              alert(response.message);
              item.IsLinked = true;
            } else {
              if(this.localAllPrograms !== null && this.localAllPrograms !== undefined) {
                this.localAllPrograms[objIndex] = item;
              }
              // need information send to signalr
              const customCategoryItem = {
                dataItem: this.localAllPrograms,
                programDetailType: 'LinkedProgram',
              };
              this.$emit('linked-category-add-program-notification', customCategoryItem);
              this.isLinkedItem = false;

            }
          })
          .catch(() => {
            // Log error if required
          });

      }
    } else {
      item.IsLinked = false;
    }
  }


  private SaveLinkedPrograms() {
    const objIndex = this.localAllPrograms!.findIndex(
      (obj: ProgramItem) => obj.IsLinked === true,
    );
    if (objIndex > -1) {
      const infoItems: number[] = [];
      this.localAllPrograms!.forEach(function(value) {
        if (value.IsLinked === true) {
          infoItems.push(value.Id);
        }
      });
      const customCategoryIds: number[] = this.selectedItems!; // this.signalR.GetSelectedCustomCategoryIdsToSendInJson(this.selectedItems!);

      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({overwriteLinkedPrograms: this.overwriteLinkedProgram, LinkedinfoItems: infoItems});
      this.localSelectedBulkOperationModel.SelectedIds = customCategoryIds;
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.signalR.PerformCustomCategoryBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('customCategory-uncheck-select-all');
    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('InformationAddProgram.SelectProgram').toString());
      this.visibleValidationSummary = true;
    }
  }

  // Get imgUrl for Information item
  private GetImageUrl(imgurl: string) {
    return imgurl === null || imgurl === ''? this.missingImage : imgurl;
  }
  private isDisableBtn() {
    if (this.entityType === ModuleEnum.Dialogue && this.isLinkedItem) {
      return true;
    } else {
      return false;
    }
  }
}
export default toNative(AddProgramWithEntity);
