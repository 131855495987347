import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-533ec774"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  id: "myTabContent",
  class: "tab-content border-radius-10"
}
const _hoisted_3 = {
  key: 0,
  class: "chart-area"
}
const _hoisted_4 = {
  key: 1,
  class: "div-empty-response-text"
}
const _hoisted_5 = { class: "empty-response-text-span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationTitleAndViewLiveResponses = _resolveComponent("NotificationTitleAndViewLiveResponses")!
  const _component_HighchartSpiderChart = _resolveComponent("HighchartSpiderChart")!
  const _component_HighchartWordCloud = _resolveComponent("HighchartWordCloud")!
  const _component_HighchartBarChart = _resolveComponent("HighchartBarChart")!
  const _component_HighchartPieChart = _resolveComponent("HighchartPieChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.localNotificationItem !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_NotificationTitleAndViewLiveResponses, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"]),
              _createElementVNode("div", null, [
                (_ctx.totalUsersFeedbacksCount > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.SpiderChart)
                          ? (_openBlock(), _createBlock(_component_HighchartSpiderChart, {
                              key: 0,
                              "chart-data": _ctx.commonChartData
                            }, null, 8, ["chart-data"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.WordCloud)
                        ? (_openBlock(), _createBlock(_component_HighchartWordCloud, {
                            key: 0,
                            "chart-data": _ctx.localNotificationItem.Wordclouds
                          }, null, 8, ["chart-data"]))
                        : _createCommentVNode("", true),
                      (_ctx.IsBarChart())
                        ? (_openBlock(), _createBlock(_component_HighchartBarChart, {
                            key: 1,
                            "chart-data": _ctx.commonChartData
                          }, null, 8, ["chart-data"]))
                        : _createCommentVNode("", true),
                      (_ctx.localNotificationItem.SelectedChartTypeId === _ctx.chartTypeEnum.PieChart)
                        ? (_openBlock(), _createBlock(_component_HighchartPieChart, {
                            key: 2,
                            "chart-data": _ctx.commonChartData
                          }, null, 8, ["chart-data"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("Notification.DialogueResults.ZeroResponses")), 1)
                    ]))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}