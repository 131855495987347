import NotificationEnum from '@/enums/NotificationEnum';
export class NotificationIsChartVisibleEnumParse {
  public static GetIsChartVisible(typeId: number): boolean {
    let value = false;
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  false;
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = false;
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = true;
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = true;
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = false;
      break;
    case NotificationEnum.PickCorrect:
      value = true;
      break;
    case NotificationEnum.RankOptionItems:
      value = true;
      break;
    case NotificationEnum.ScaleStatements:
      value = true;
      break;
    }
    return value;
  }
}
export class NotificationIsLiveResponsesVisibleEnumParse {
  public static GetIsLiveResponsesVisible(typeId: number): boolean {
    let value = false;
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  false;
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = true;
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = false;
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = false;
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = true;
      break;
    case NotificationEnum.PickCorrect:
      value = false;
      break;
    case NotificationEnum.RankOptionItems:
      value = false;
      break;
    case NotificationEnum.ScaleStatements:
      value = false;
      break;
    }
    return value;
  }
}
export class NotificationChartTypeForDialoguesEnumParse {
  public static GetIChartTypeForDialogues(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  '';
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = '';
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = 'bar';
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = 'bar';
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = '';
      break;
    case NotificationEnum.PickCorrect:
      value = 'bar';
      break;
    case NotificationEnum.RankOptionItems:
      value = 'rank';
      break;
    case NotificationEnum.ScaleStatements:
      value = 'scalingbar';
      break;
    }
    return value;
  }
}
export class NotificationChartTypeForSurveyDialoguesEnumParse {
  public static GetIChartTypeForDialoguesSurvey(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  '';
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = '';
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = 'pie';
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = 'pie';
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = '';
      break;
    case NotificationEnum.PickCorrect:
      value = 'pie';
      break;
    case NotificationEnum.RankOptionItems:
      value = 'rank';
      break;
    case NotificationEnum.ScaleStatements:
      value = 'scalingbar';
      break;
    }
    return value;
  }
}
export class NotificatioDialoguesSeriesEnumParse {
  public static GetChartSeriesForDialogues(typeId: number): any {
    let value: any = '';
    switch (typeId) {
    case NotificationEnum.RankOptionItems:
      value  = { align: 'left', color: '#ffffff', format: '{point.DataResult}' };
      break;
    case NotificationEnum.ScaleStatements:
      value  = { useHTML: true, enabled: true, format: '{y}', color: '#ffffff' };
      break;
    default :
      value = { useHTML: true, enabled: true, formatter() {
        if (this.y !== 0) {
          return '<span style="color: #ffffff;">' + this.y + '%' + '</span>';
        } else {
          return '<span>' + '0%' + '</span>';
        }
      },
      };
      break;
    }
    return value;
  }
}
export class NotificatioSurveyDialoguesSeriesEnumParse {
  public static GetIChartSeriesForSurveyDialogues(typeId: number): any {
    let value: any = '';
    switch (typeId) {
    case NotificationEnum.RankOptionItems:
      value  = { align: 'left', color: '#ffffff', format: '{point.DataResult}' };
      break;
    case NotificationEnum.ScaleStatements:
      value  = { align: 'left', color: '#ffffff', format: '{y}' };
      break;
    default :
      value = { useHTML: true, enabled: true, color: '#000000', formatter() {
        if (this.y !== 0) {
          return this.y + '%';
        } else {
          return null;
        }
      },
      };
      break;
    }
    return value;
  }
}
export class NotificationDialoguesLegendsEnumParse {
  public static GetIsLegendsVisible(typeId: number): boolean {
    let value = false;
    switch (typeId) {
    default:
      value = false;
      break;
    }
    return value;
  }
}
export class NotificationSurveyLegendsEnumParse {
  public static GetIsLegendsVisibleforSurvey(typeId: number): boolean {
    let value = true;
    switch (typeId) {
    case NotificationEnum.RankOptionItems:
      value = false;
      break;
    case NotificationEnum.ScaleStatements:
      value = false;
      break;
    default:
      value = true;
      break;
    }
    return value;
  }
}
export class NotificationDialoguesToolTipEnumParse {
  public static GetToolTipDialogues(typeId: number): any {
    let value: any = null;
    switch (typeId) {
    default:
      value = { enabled: false };
      break;
    }
    return value;
  }
}
export class NotificationSurveyToolTipEnumParse {
  public static GetToolTipSurvey(typeId: number): boolean {
    let value: any = null;
    switch (typeId) {
    case NotificationEnum.ScaleStatements:
      value = { enabled: false };
      break;
    default:
      value = { enabled: true,
        formatter() {
          // eslint-disable-next-line max-len
          return this.key + '</br> <span style="font-weight: 600;">' + '(' + this.point.DataResult + ' Users) </span>'  + this.y + '%';
        }};
      break;
    }
    return value;
  }
}
