
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import Datepicker from '@/components/Common/Datepicker.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import { FetchData, Root, Store } from '@/main';

@Component({
  components: {
    Datepicker,
  },
})
class NotificationFooterPane extends Vue {
// Properties
  @Prop() private isCreateNew?: boolean;
  @Prop() private folders?: string[];
  @Prop() private folder?: string;
  @Prop() private filterId?: number;
  @Prop() private accesscode?: string;
  @Prop() private filters?: NotificationFilter[];
  @Prop() private isPublished?: string;
  @Prop() private scheduledDate?: string;

  private storeHelper: StoreHelper = new StoreHelper(Store);
  // local variables
  private availableNotificationFolders: string[] = [];
  private localFolder = '';
  private localAccessCode = '';
  private localisPublished = false;
  private localscheduleDate = '';
  private localfilerId = 0;
  private localFilters: NotificationFilter[] = [];

  private IsCreateNewFilter = true;
  private IsCreateNewFolder = true;
  private IsSelectExisting = false;
  private IsSelectAudience = false;
  private filterparticipantCount: any = null;
  private selectedNotificationFilter = '';
  private participantCount = 0;

  private mounted() {
    if (this.filters !== undefined && this.filters !== null) {
      this.localFilters =  JSON.parse(JSON.stringify(this.filters));
    }
  }

  private created() {
    this.availableNotificationFolders = this.folders!;
    if (this.availableNotificationFolders !== undefined) {
      const objIndex = this.availableNotificationFolders.findIndex(
        (obj: string) => obj === null || obj === '',
      );
      this.availableNotificationFolders[objIndex] = this.$t('Dialogue.TopLevel').toString();
    }

    if (this.folder === null) {
      this.localFolder = this.$t('Dialogue.TopLevel').toString();
    } else {
      this.localFolder = this.folder!;
    }
    this.localAccessCode = this.accesscode!;
    this.localisPublished = Boolean(this.isPublished);
    this.localscheduleDate = this.scheduledDate!;
    this.localfilerId = this.filterId!;
    this.setSeletedFilter();
  }
  private changegroup(folder: any) {
    if (folder  !== undefined || folder !== null) {
      const groupVal = String(folder.target.value);
      if (groupVal === this.$t('Dialogue.TopLevel').toString()) {
        this.localFolder = '';
      } else {
        this.localFolder = groupVal;
      }
    }
    this.$emit('setGroup', this.localFolder);
  }
  private changeaccesscode() {
    this.$emit('setAccessCode', this.localAccessCode);
  }
  private showCreateNewOption() {
    this.IsSelectExisting = true;
    this.IsCreateNewFolder = false;
    if(this.$refs.CreateNewFolder !== null && this.$refs.CreateNewFolder !== undefined) {
      (this.$refs.CreateNewFolder as HTMLElement).focus();
    }
  }
  private showExistingOptions() {
    this.localFolder = '';
    this.IsSelectExisting = false;
    this.IsCreateNewFolder = true;
  }
  private showFilters() {
    this.IsSelectAudience = true;
  }
  private hideFilters() {
    this.IsSelectAudience = false;
    this.localfilerId = 0;
    this.$emit('setFilter', this.localfilerId);
  }
  private setSeletedFilter() {
    if (this.localfilerId > 0 ) {
      if (this.filters !== undefined) {
        const objindex = this.filters.findIndex(
          (obj: NotificationFilter) => obj.Id === this.localfilerId,
        );
        if (objindex > -1) {
          this.selectedNotificationFilter = this.filters[objindex].FilterName;
        }
        this.GetParticipantCount(this.localfilerId.toString(), true);
      }
    }
  }
  private ShowCreateNewFilterPane() {
    Root.emit('notification-show-filter-pane', true);
  }
  private GetParticipantCount(event: any, isEditMode: boolean) {
    let selectedfilterId = 0;
    if (isEditMode) {
      selectedfilterId = Number(event);
    } else {
      selectedfilterId = Number(event.target.value);
    }
    this.$emit('setFilter', selectedfilterId);
    FetchData('/Notification/GetParticipantsCountByFilterId', JSON.stringify({
      filterId: selectedfilterId,
    }))
      .then((response) => {
        const participantcount = response;
        this.filterparticipantCount = participantcount;
        this.participantCount = this.filterparticipantCount.userFilters;
      })
      .catch(() => {
        // here
      });
  }
  private setSelectedFolder(): string {
    if (this.availableNotificationFolders.indexOf(this.localFolder) > -1) {
      return this.localFolder;
    } else if (this.localFolder === '') {
      return this.$t('Dialogue.TopLevel').toString();
    } else {
      this.showCreateNewOption();
      return this.localFolder;
    }
  }
  @Watch('participantCount')
  @Watch('isPublished')
  private onNotificationItemPropertyChange(
    val: string,
  ) {
    this.localisPublished = Boolean(val);
  }
  @Watch('scheduleDate')
  private onNotification(
    val: string,
  ) {
    this.localscheduleDate = val;
  }
  @Watch('filterId')
  private onmainGroupIdChange(val: number) {
    this.localfilerId = val;
    this.setSeletedFilter();
  }
  @Watch('folder')
  private onGroupChange(val: string) {
    if (val == null) {
      this.localFolder = this.$t('Dialogue.TopLevel').toString();
    } else {
      this.localFolder = val;
    }
  }
  @Watch('accesscode')
  private onAccessCodeChange(val: string) {
    this.localAccessCode = val;
  }
  @Watch('folders')
  private onLocalFolderChange() {
    if (this.availableNotificationFolders.includes(null!)) {
      const index = this.availableNotificationFolders.indexOf(null!, 0);
      if (index > -1) {
        this.availableNotificationFolders.splice(index, 1);
      }
      this.availableNotificationFolders.splice(0, 0, this.$t('Dialogue.TopLevel').toString());
    }
  }
}
export default toNative(NotificationFooterPane);
