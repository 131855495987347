
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import { HighChartSeriesPointFormat } from '@/entities/Notification/NotificationResults';
import { NotificationChartTypeForSurveyDialoguesEnumParse } from '@/entities/Notification/NotificationResultsSettings';
import HighchartPieChart from '@/components/Common/HighchartPieChart.vue';
import HighchartWordCloud from '@/components/Common/HighchartWordCloud.vue';
import HighchartBarChart from '@/components/Common/HighchartBarChart.vue';
import HighchartSpiderChart from '@/components/Common/HighchartSpiderChart.vue';
import { NotificationDetailViewModel } from '@/entities/Notification/NotificationDetailViewModel';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import HighchartHelper from '@/helper/HighchartHelper';
import { Root } from '@/main';
@Component({
  components: {
    HighchartPieChart,
    HighchartWordCloud,
    HighchartBarChart,
    HighchartSpiderChart,
  },
})
class NotificationSurveyHighCharts extends Vue{
  @Prop() private selectedNotificationItem!: NotificationItem;
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private commonChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private chartType = '';
  private chartCategories: string[] = [];
  private chartData: any[] = [];
  private totalUsersFeedbacksCount = 0;// Number of user responses
  public get chartTypeEnum(): typeof ChartTypeEnum {
    return ChartTypeEnum;
  }
  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem);
    this.SetUserFeedbackCount();
    this.GetChartType();
    this.SetCommonChartData();
    // this updates the data for charts
    Root.on('update-notification-details',(val: NotificationDetailViewModel)=> {
      if (this.localNotificationItem.Id === val.Id) {
        this.localNotificationItem.RecipientCount = val.RecipientCount;
        this.localNotificationItem.HighChartData = val.OptionResults;
        this.localNotificationItem.RankingHighChartData = val.RankNotificationResults;
        this.localNotificationItem.ScalingHighChartData = val.ScaleNotificationResults;
        this.localNotificationItem.UserFeedback = val.UserFeedback;
        this.localNotificationItem.Wordclouds = val.Wordclouds;
        this.localNotificationItem.NotificationFeedbacks = val.NotificationFeedbacks;
        this.SetCommonChartData();
      }
    });
  }
  private IsBarChart() {
    return (this.localNotificationItem.SelectedChartTypeId === ChartTypeEnum.BarChart || this.localNotificationItem.SelectedChartTypeId === ChartTypeEnum.RankingBarChart ||this.localNotificationItem.SelectedChartTypeId === ChartTypeEnum.SliderChart);
  }
  // This sets the total count of number of user feedbacks
  private SetUserFeedbackCount() {
    if(this.localNotificationItem.UserFeedback !==undefined) {
      this.totalUsersFeedbacksCount = this.localNotificationItem.UserFeedback.length;
    }
  }
  // this helps in sending the formatted required data to charts via a common variable
  private SetCommonChartData() {
    this.chartCategories = [];
    this.chartData = [];
    this.GetChartData();
    this.commonChartData = CommonHighchartData.createEmpty();
    if(this.localNotificationItem.NotificationDimensions!== undefined) {
      const maxIndex = this.localNotificationItem.NotificationDimensions.length;
      switch (this.chartType) {
      case HighchartHelper.ScalingChart:
        this.commonChartData = CommonHighchartData.SetScalingChartData(this.chartCategories,this.chartData,this.chartType,this.localNotificationItem.NotificationDimensions[maxIndex - 1].NotificationDimensionValue,this.localNotificationItem.NotificationDimensions[0].NotificationDimensionValue);
        break;
      case HighchartHelper.RankingChart:
        this.commonChartData = CommonHighchartData.SetRankingChartData(this.chartCategories,this.chartData,this.chartType);
        break;
      default:
        this.commonChartData = CommonHighchartData.SetBarChartData(this.chartCategories,this.chartData,this.chartType);
        break;
      }
    }
  }
  // This gets the chart data from the notification item object
  private GetChartData() {
    if (this.selectedNotificationItem !== null && this.selectedNotificationItem !== undefined) {
      // Set data for SCQ/MCQ/ Pick correct
      if ((this.chartType === HighchartHelper.BarChart || this.chartType === HighchartHelper.PieChart) && this.localNotificationItem.HighChartData !== null && this.localNotificationItem.HighChartData !== undefined) {
        this.localNotificationItem.HighChartData.Results!.forEach((element) => {
          this.chartCategories.push(element.Option);
          this.chartData.push({percentage: element.PercentageOfCastVotes,votes:element.NumberOfVotes});
        });
      } else if (this.chartType === HighchartHelper.RankingChart && this.localNotificationItem.RankingHighChartData!== null && this.localNotificationItem.RankingHighChartData!== undefined) {
        // Set chart data for Rank dialogue
        this.localNotificationItem.RankingHighChartData.Results!.forEach((element) => {
          this.chartCategories.push(element.Text);
          /* HighChartSeriesPointFormat is used where we have to display three data on
        chart (y / Data result/ percentageVotes). */
          const RnkData = HighChartSeriesPointFormat.createEmpty();
          RnkData.y = element.Score;
          RnkData.DataResult = element.FinalRank;
          this.chartData.push(RnkData);
        });
      } else {
        if (this.localNotificationItem !== null && this.localNotificationItem.ScalingHighChartData !== undefined &&  this.localNotificationItem.ScalingHighChartData !== null) {
        // Set chart data for Scale correct
          this.localNotificationItem.ScalingHighChartData.Options!.forEach((element) => {
            this.chartCategories.push(element.Text);
          });
        }
        if ( this.localNotificationItem !== null && this.localNotificationItem.ScalingHighChartData !== null &&  this.localNotificationItem.ScalingHighChartData !== undefined) {
          this.localNotificationItem.ScalingHighChartData.DataResults!.forEach((element) => {
            this.chartData.push(element);
          });
        }
      }
    }
  }
  private GetChartType() {
    this.chartType = NotificationChartTypeForSurveyDialoguesEnumParse.GetIChartTypeForDialoguesSurvey(this.localNotificationItem.NotificationTypeId);
  }
  @Watch('selectedNotificationItem',{deep: true})
  private CheckChangeInSelectedNotificationItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
    this.SetUserFeedbackCount();
  }
}
export default toNative(NotificationSurveyHighCharts);
