import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, vModelRadio as _vModelRadio, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0284166d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "display-flex position-relative"
}
const _hoisted_2 = { class: "heading description-heading no-margin" }
const _hoisted_3 = { class: "tooltip-container" }
const _hoisted_4 = { class: "margin-top-5" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "inline-flex description margin-top-5" }
const _hoisted_7 = { class: "padding-1 margin-left-5" }
const _hoisted_8 = {
  key: 2,
  class: "inline-flex"
}
const _hoisted_9 = {
  key: 0,
  for: "linkProgram",
  class: "description"
}
const _hoisted_10 = {
  key: 3,
  class: "display-flex div-link-item-btns margin-top-10"
}
const _hoisted_11 = {
  key: 0,
  class: "div-link-program-btn"
}
const _hoisted_12 = {
  key: 1,
  class: "div-link-information-btn"
}
const _hoisted_13 = {
  key: 4,
  class: "div-show-linked-entity margin-top-10"
}
const _hoisted_14 = { class: "display-flex linked-item-container" }
const _hoisted_15 = { class: "display-flex" }
const _hoisted_16 = { class: "img-select display-inline" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "select-information-style display-inline" }
const _hoisted_19 = { class: "valign" }
const _hoisted_20 = { class: "linked-item-icon" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 5,
  class: "div-show-linked-entity margin-top-10"
}
const _hoisted_23 = { class: "display-flex linked-item-container" }
const _hoisted_24 = { class: "display-flex" }
const _hoisted_25 = { class: "img-select display-inline" }
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "select-information-style display-inline" }
const _hoisted_28 = { class: "valign" }
const _hoisted_29 = { class: "linked-item-icon" }
const _hoisted_30 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.enableProgram || _ctx.enableInformation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.heading), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("i", {
              class: "fa fa-info-circle linked-item-heading-tooltip",
              "aria-hidden": "true",
              onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GetInformationString(true))),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.GetInformationString(false)))
            }, null, 32),
            _withDirectives(_createElementVNode("span", { class: "linked-item-information tooltip-prop" }, _toDisplayString(_ctx.$t("Notification.LinkedEntity.RequireMobileAppVersion")), 513), [
              [_vShow, _ctx.isLinkedItemTooltipInfoVisible]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.description), 1)
    ]),
    (_ctx.enableProgram || _ctx.enableInformation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              id: "inputLinkedEntityWithDialogue",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isItemLinkedWithDialogue) = $event)),
              type: "checkbox",
              name: "linkAnItem",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SelectItemLinkedWithDialogue()))
            }, null, 512), [
              [_vModelCheckbox, _ctx.isItemLinkedWithDialogue]
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("Notification.LinkedEntity.LinkAnItem")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isItemLinkedWithDialogue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.enableProgram)
            ? (_openBlock(), _createElementBlock("label", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  id: "linkProgram",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialogueLinkedWithinformation) = $event)),
                  type: "radio",
                  name: "linkDialogueItem",
                  value: false
                }, null, 512), [
                  [_vModelRadio, _ctx.isDialogueLinkedWithinformation]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Notification.LinkedEntity.LinkAProgram")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.enableInformation)
            ? (_openBlock(), _createElementBlock("label", {
                key: 1,
                for: "linkInformation",
                class: _normalizeClass([{'margin-left-10': _ctx.enableProgram}, "description"])
              }, [
                _withDirectives(_createElementVNode("input", {
                  id: "linkInformation",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogueLinkedWithinformation) = $event)),
                  type: "radio",
                  name: "linkDialogueItem",
                  class: "margin-left-10",
                  value: true
                }, null, 512), [
                  [_vModelRadio, _ctx.isDialogueLinkedWithinformation]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Notification.LinkedEntity.LinkAnInformation")), 1)
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isItemLinkedWithDialogue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.IsLinkedProgramBtnVisible())
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "btn btn-size-header-main btn-secondary",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.BtnClickAddEntity(1)))
                }, _toDisplayString(_ctx.$t("Notification.LinkedEntity.LinkAProgram")), 1)
              ]))
            : (_ctx.IsLinkedInformationBtnVisible())
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "btn btn-size-header-main btn-secondary",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.BtnClickAddEntity(2)))
                  }, _toDisplayString(_ctx.$t("Notification.LinkedEntity.LinkAnInformation")), 1)
                ]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.IsLinkedInformationVisible())
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("img", {
                  class: "iconImg",
                  src: _ctx.GetImageUrl(_ctx.localLinkedInformation.ThumbUrl)
                }, null, 8, _hoisted_17)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.localLinkedInformation.Title), 1)
              ])
            ]),
            _createElementVNode("div", {
              class: "div-change-custom-category hyperLink",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.BtnClickAddEntity(2)))
            }, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("img", {
                  class: "width80",
                  src: _ctx.linkedItem
                }, null, 8, _hoisted_21)
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Notification.LinkedEntity.ChangedLinkedItem")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.IsLinkedProgramVisible())
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("img", {
                  class: "iconImg",
                  src: _ctx.GetImageUrl(_ctx.localLinkedProgram.ImageUrl)
                }, null, 8, _hoisted_26)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.localLinkedProgram.Title), 1)
              ])
            ]),
            _createElementVNode("div", {
              class: "div-change-custom-category hyperLink",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.BtnClickAddEntity(1)))
            }, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("img", {
                  class: "width80",
                  src: _ctx.linkedItem
                }, null, 8, _hoisted_30)
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Notification.LinkedEntity.ChangedLinkedItem")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}