
import { UserFeedbackModel } from '@/entities/Notification/NotificationResults';
import { EnvironmentBaseURL, FetchData, Root } from '@/main';
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';
@Component
class NotificationDiscussSelected extends Vue {
  @Prop() private userFeedbacks?: UserFeedbackModel[];

  private selectedItems: UserFeedbackModel[] = [];

  private isMobileView = false;
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    Root.emit('new-notification-edit-item-pane', true);
  }
  private Close() {
    Root.emit('notification-show-discuss-selected-pane', false);
  }
  private Display() {
    if (this.selectedItems.length > 0) {
      const selectedFeddbackids: number[] = [];
      this.selectedItems.forEach((value) => {
        selectedFeddbackids.push(value.Id);
      });
      FetchData('/Notification/GetResultUrl', JSON.stringify({
        ids: selectedFeddbackids.toString(),
      }))
        .then((responsedata) => {
          const url = responsedata.encrypUrl;
          const mainurl = EnvironmentBaseURL + 'Public/ViewNotification/Index?notificationIds=' + url;
          window.open(mainurl, '_blank');
        });
    }
  }
}
export default toNative(NotificationDiscussSelected);
