
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import FileUpload from '@/components/Common/FileUpload.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import NotificationEnum from '@/enums/NotificationEnum';
import Common from '@/helper/Common';
import { NotificationItem, NotificationItemSignalR } from '@/entities/Notification/NotificationItem';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import { NotificationFilterViewModel } from '@/entities/Notification/NotificationFilterViewModel';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import { NotificationSurveySignalRModel } from '@/signalRRequest/Notification/NotificationSurveySignalRModel';
import NotificationEditItemPane from '@/components/Notification/NotificationDetail/NotificationEditItemPane.vue';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';
import NotificationSchedule from '@/components/Notification/NotificationCommon/NotificationSchedule.vue';
import NotificationSelectAudienceFilter from '@/components/Notification/NotificationCommon/NotificationSelectAudienceFilter.vue';
import NotificationSurvey from '@/components/Notification/Survey/NotificationSurvey.vue';
import moment from 'moment-timezone';
import { NotificationDetailViewModel } from '@/entities/Notification/NotificationDetailViewModel';
import SendPushOnPublish from '@/components/Notification/NotificationCommon/SendPushOnPublish.vue';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import NotificationDiscussSelected from '../NotificationResults/NotificationDiscussSelected.vue';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { FetchData, Root, Store, t } from '@/main';
import { reactive } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import SubModulePrefix from '@/enums/SubModulePrefix';

@Component({
  components: {
    loading,
    ModalPopup,
    FileUpload,
    NotificationEditItemPane,
    NotificationSurvey,
    ValidationSummary,
    NotificationSchedule,
    NotificationSelectAudienceFilter,
    SendPushOnPublish,
    VueModalPopUp,
    NotificationDiscussSelected,
  },
})
class NotificationParentEditPane extends Vue {
  // Properties
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private segmentQuestions?: NotificationSegmentQuestion[];
  @Prop() private filters?: NotificationFilter[];
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private availableCustomCategoryItems?: CustomCategoryItem[];
  @Prop() private availableProgramItems?: ProgramItem[];
  // constants
  private isMobileView = false;
  private readonly NotificationDialogueDeleted = 'NotificationDialogueDeleted';
  private readonly NotificationSurveyDeleted = 'NotificationSurveyDeleted';
  private readonly NotificationUnscheduleItem = 'NotificationUnscheduleItem';
  private readonly NotificationDialogueCopy = 'NotificationDialogueCopy';
  private readonly NotificationSurveyCopy = 'NotificationSurveyCopy';
  private localNotificationoptions: NotificationOptions | null = null;

  private localNotificationItem: NotificationItem | null = null;
  private localNotificationSurveyItem: NotificationItem | null = null;
  private oldQuestionarieValue: NotificationItem[] = [];
  private filterViewModel?: NotificationFilterViewModel | null = null;
  private newFilter: NotificationFilter = NotificationFilter.createEmpty();
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private schedultedDate: Date = new Date();
  private showComponent: number | null = 0;
  private activeSubComponent = '';
  private notificationTypeId = -1;
  private showFileUploadProcess = false;
  private showPublishLater = false;
  private showFilter = false;
  private IsEditControllersVisible = true;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private readonly defaultImageWidth = 320;
  private readonly defaultImageHeight = 200;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private timeZones: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private ReloadNotification = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private isIEbrowser = false;
  private localparticipantSegmantQuestions: NotificationSegmentQuestion[] = [];

  // Model Popup
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalHeader = '';
  private modalPopupContent = '';
  private modalPopupHeading = '';
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  // Vue Model Popup
  private showVueModalPopUp = false;
  private unsavedVueModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();

  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private notificationItemTobeSaved: NotificationItem = NotificationItem.createEmpty();
  private showLoading = false;
  private loaderBorderColor = '';
  private errors: string[] = [];
  private messages: string[] = [];
  private moduleName = 'Notification';
  private subModuleName = SubModulePrefix.Multimedia;
  private showDiscussSelected = false;
  private dropDownIcon = require('@/assets/Images/down-arrow-white.svg');
  private state = reactive({
    localNotificationItem: null as NotificationItem | null,
  });

  private rules = {
    localNotificationItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }
  private mounted() {


    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
    this.initializeTimeFormat();
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem);
    this.state.localNotificationItem = this.localNotificationItem;
    this.GetparticipantSegmentsOnly();

    if (this.localNotificationItem.NotificationTypeId === NotificationEnum.Survey) {
      this.localNotificationItem.IsSurvey = true;
      this.localNotificationItem.QuestionnaireNotifications = undefined;
    }

    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;

    this.localNotificationItem.IsScheduled = this.GetIsPublished(this.localNotificationItem);

    Root.on('notification-include-image-file-upload', (val: boolean) => {
      this.clickOnImage(val);
      this.uncheckPreviousNotificationCheckbox();
      this.UncheckAllCheckBoxesForcefully();
    });
    Root.on('notification-is-image-included', (val: boolean) => {
      this.localNotificationItem!.IsIncludeImage = val;
    });
    Root.on('notification-is-linked-an-item', (val: boolean) => {
      this.localNotificationItem!.IsAnyLinkedItemWithNotification = val;
    });
    Root.on('notification-edited-to-parent', (notificationItem: NotificationItem) => {
      Root.emit('notification-edit-pane-values-for-create', notificationItem);
      this.localNotificationItem = notificationItem;
      this.state.localNotificationItem = this.localNotificationItem;
    });
    Root.on('notification-set-schedule-date', (scheduleDate: Date) => {
      this.setScheduleDate(scheduleDate);
    });
    Root.on('notification-show-filter-pane', (val: boolean) => {
      this.NotificationFilterShowHide(val);
      // this.uncheckPreviousNotificationCheckbox();
      // this.UncheckAllCheckBoxesForcefully();
      this.GetFilterProperties();
    });
    Root.on('notification-show-discuss-selected-pane', (val: boolean) => {
      this.NotificationDiscussSelectedShowHide(val);
      this.uncheckPreviousNotificationCheckbox();
    });
    Root.on('notification-hide-edit-item-buttons', (val: boolean) => {
      this.IsEditControllersVisible = val;
    });


    Root.on('notification-send-to-signalr', () => {
      const signalObject =  this.SendNotificationRequest(false);
      this.SendNotificationRequestToQueue(
        signalObject,
        this.selectedNotificationItem!,
      );
    });

    Root.on(
      'new-notification-edit-item-pane',
      (isNewEditItemPane: boolean) => {
        if (isNewEditItemPane) {
          // scroll to top if any program edit or new program clicked
          if (!this.isMobileView) {
            Common.ScrollToTop('notificationEditMode');
          }
        }
      },
    );
    Root.on(
      'update-notification-details',
      (selectedNotificationItem: NotificationDetailViewModel) => {
        if (this.localNotificationItem!.Id === selectedNotificationItem.Id) {
          this.localNotificationItem!.RecipientCount = selectedNotificationItem.RecipientCount;
          this.localNotificationItem!.UserFeedback = selectedNotificationItem.UserFeedback;
          this.localNotificationItem!.NotificationFeedbacks = selectedNotificationItem.NotificationFeedbacks;
          this.localNotificationItem!.IsAnyLinkedItemWithNotification = selectedNotificationItem.IsAnyLinkedItemWithNotification;
        }
      },
    );
    Root.on('notification-show-overlay', (val: boolean) => {
      this.ReloadNotification = val;
    });
    this.unsavedVueModalPopUp = new VueModalPopUpViewModel(
      t('Unpublish').toString(),
      t('Dialogue.UnpublishDescription').toString(),
      false,
      '',
      t('CancelButton').toString(),
      t('Unpublish').toString(),
    );
  }
  private beforeUnmount() {
    Root.off('notification-send-to-signalr');
  }
  private Close() {
    this.$emit('notification-add-item-cancel-operation');
  }
  private Cancel() {
    if (this.localNotificationItem!.Id > 0 || this.localNotificationItem!.IsSurvey) {
      Root.emit('clearNotificationCheckboxes', []);
      Root.emit('set-notification-landing-page', true);
    } else {
      Root.emit('notification-create-new-pane-show');
    }
    this.UncheckAllCheckBoxesForcefully();
  }
  private ShowNotificationEditDetailView() {
    return (
      this.localNotificationItem &&
      !this.showFileUploadProcess &&
      !this.showPublishLater &&
      !this.showDiscussSelected &&
      !this.showFilter
    );
  }
  private ShowFileUploadSection() {
    return (
      this.localNotificationItem &&
      this.showFileUploadProcess &&
      !this.showPublishLater &&
      !this.showDiscussSelected &&
      !this.showFilter
    );
  }
  private ShowPublishLater() {
    return (
      this.localNotificationItem &&
      !this.showFileUploadProcess &&
      !this.showFilter &&
      !this.showDiscussSelected &&
      this.showPublishLater
    );
  }
  private ShowSelectAudienceFilter() {
    return (
      this.localNotificationItem &&
      !this.showFileUploadProcess &&
      this.showFilter &&
      !this.showDiscussSelected &&
      !this.showPublishLater
    );
  }

  private ShowDiscussSelected() {
    return (
      this.localNotificationItem &&
      !this.showFileUploadProcess &&
      !this.showFilter &&
      !this.showPublishLater &&
      this.showDiscussSelected
    );
  }

  private PublishLaterShowHide(val: boolean) {
    if (val === false) { // Close Schedule panal
      this.localNotificationItem!.ScheduleDate = null!;
      this.localNotificationItem!.PublishNow = 'true';
    } else {
      this.localNotificationItem!.PublishNow = 'false';
    }
    this.showPublishLater = val;
    this.uncheckPreviousNotificationCheckbox();
    this.UncheckAllCheckBoxesForcefully();
  }
  private NotificationFilterShowHide(val: boolean) {
    this.showFilter = val;
  }
  private clickOnImage(val: boolean) {
    this.showFileUploadProcess = val;
    Root.emit('notification-uncheck-checkbox-forcefully');
  }
  private GetValidationType() {
    if (this.showFileUploadProcess === true) {
      return 'lessthan';
    }
  }
  private GetIconImageSize() {
    if (this.showFileUploadProcess === true) {
      return 154;
    }
  }
  private GetAcceptedFileTypes() {
    const acceptedFileTypes: string[] = [];
    if (this.showFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
    }
    return acceptedFileTypes;
  }
  private GetFileExtensions() {
    if (this.showFileUploadProcess === true) {
      return '.png,.jpg,.jpeg';
    }
  }
  private GetImageUrl() {
    return this.missingImage;
  }
  private GetThumbnailImageUrl() {

    return (this.localNotificationItem!.ImageThumbnailUrl === '' || this.localNotificationItem!.ImageThumbnailUrl === null)
      ? this.missingImage
      : this.localNotificationItem!.ImageThumbnailUrl;
  }
  private GetfeaturedimageFullUrl() {
    if (
      this.localNotificationItem!.ImageFullUrl === '' ||
      this.localNotificationItem!.ImageFullUrl === null
    ) {
      this.localNotificationItem!.FeaturedImageWidth = this.defaultImageWidth;
      this.localNotificationItem!.FeaturedImageHeight = this.defaultImageHeight;
      return this.featureMissingImage;
    } else {
      return this.localNotificationItem!.ImageFullUrl;
    }
  }
  private GetImageProcessHeadingTitle(): string {
    return '';
  }
  private CheckFeaturedImage() {
    return this.localNotificationItem!.ImageFullUrl === '' ||
      this.localNotificationItem!.ImageFullUrl === null
      ? false
      : true;
  }
  private GetValidationMessage() {
    if (this.showFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOnly';
    }
  }
  private GetImageType() {
    return '';
  }
  private SetImageIconUrl(
    imgThumbnailurl: string,
    ImageFullUrl: string,
  ) {
    if (this.showFileUploadProcess === true) {
      this.localNotificationItem!.ImageThumbnailUrl = imgThumbnailurl;
      this.localNotificationItem!.ImageFullUrl = ImageFullUrl;
      if (this.selectedNotificationItem!.Id === 0) {
        Root.emit('notification-create-dialogue-image-uploaded', imgThumbnailurl, ImageFullUrl);
      }
    }
  }
  private SetFilter(val: any) {
    this.newFilter.Id = val.data.mainFilterId;
    this.newFilter.FilterName = val.data.mainFilter;
    this.localNotificationItem!.FilterId = this.newFilter.Id;
    this.localNotificationItem!.Filter = this.newFilter.FilterName;
    this.filters!.push(this.newFilter);
  }
  private ClickDeleteNotification() {
    // show modal popup for confirmation
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.modalHeader = this.$t('Delete').toString();
    if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.Survey) {
      this.showSectionModalPopup = this.NotificationSurveyDeleted;
      this.modalPopupContent = this.$t('Dialogue.SurveyDelete').toString();
    } else {
      this.showSectionModalPopup = this.NotificationDialogueDeleted;
      this.modalPopupContent = this.$t('Dialogue.Delete').toString();
    }
  }
  private ClickCancelScheduling() {
    // show modal popup for confirmation
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.showSectionModalPopup = this.NotificationUnscheduleItem;
    this.modalHeader = this.$t('Dialogue.CancelScheduling').toString();
    this.modalPopupContent = this.$t('Dialogue.CancelSchedulingWarning').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.NotificationDialogueDeleted) {
        this.DeleteNotification();
      } else if (this.showSectionModalPopup === this.NotificationSurveyDeleted) {
        this.DeleteNotificationSurvey();
      } else if (this.showSectionModalPopup === this.NotificationUnscheduleItem) {
        this.CancelScheduling();
      }
    } else {
      return;
    }
  }
  private UncheckAllCheckBoxesForcefully() {
    setTimeout(() => {
      const w = document.getElementsByTagName('input');
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < w.length; i++) {
        if (w[i].type === 'checkbox') {
          w[i].checked = false;
        }
      }
    }, 30);
  }
  private uncheckPreviousNotificationCheckbox() {
    setTimeout(() => {
      Root.emit('notification-uncheck-checkbox-forcefully', this.localNotificationItem!.Id);
    }, 10);
  }
  private initializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    // eslint-disable-next-line @typescript-eslint/tslint/config
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  /* for create filter view only Participant segment should be listed in the drop down
  prticipant fields must not be there in the drop down*/
  private GetparticipantSegmentsOnly() {
    this.localparticipantSegmantQuestions = [];
    if (this.segmentQuestions !== undefined && this.segmentQuestions !== null) {
      this.segmentQuestions.forEach((item) => {
        if (item.Type === 'SingleChoice') {
          this.localparticipantSegmantQuestions.push(item);
        }
      });
    }
  }
  private updateInformationInLocalCustomCategoryItem() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.state.localNotificationItem = this.localNotificationItem;
  }
  private FillNotificationItemTobeSaved() {
    // Set Id of Notification Option to zero if it is newly created

    if (this.localNotificationItem!.NotificationOptions! !== undefined && this.localNotificationItem!.NotificationOptions !== null) {
      for (let i = 0; i < this.localNotificationItem!.NotificationOptions.length; i++) {
        if (this.localNotificationItem!.NotificationOptions[i].IsNewOption) {
          this.localNotificationItem!.NotificationOptions[i].Id = 0;
        }
        this.localNotificationItem!.NotificationOptions[i].SortOrder = i + 1;
      }
    }
    if (this.localNotificationItem!.QuestionnaireNotifications !== undefined &&
        this.localNotificationItem!.QuestionnaireNotifications !== null) {
      for (let i = 0; i < this.localNotificationItem!.QuestionnaireNotifications.length; i++) {
        // if adding new options under survey set option id equal to zero
        if (this.localNotificationItem!.QuestionnaireNotifications[i].NotificationOptions !== undefined &&
        this.localNotificationItem!.QuestionnaireNotifications[i].NotificationOptions !== null) {
          for (let j = 0; j < this.localNotificationItem!
            .QuestionnaireNotifications[i]!.NotificationOptions!.length; j++) {
            if (this.localNotificationItem!.QuestionnaireNotifications[i]!.NotificationOptions![j].IsNewOption) {
              this.localNotificationItem!.QuestionnaireNotifications[i]!.NotificationOptions![j].Id = 0;
            }
            this.localNotificationItem!.QuestionnaireNotifications[i]!.NotificationOptions![j].SortOrder = j + 1;
          }
        }
        // if adding new dimension under survey set dimension id equal to zero
        if (this.localNotificationItem!.QuestionnaireNotifications[i].NotificationDimensions !== undefined &&
        this.localNotificationItem!.QuestionnaireNotifications[i].NotificationDimensions !== null) {
          for (let k   =   0 ;                                          k < this.localNotificationItem!
            .QuestionnaireNotifications[i]!.NotificationDimensions!.length; k++) {
            if (this.localNotificationItem!.QuestionnaireNotifications[i]!
              .NotificationDimensions![k].Id < 0) {
              this.localNotificationItem!.QuestionnaireNotifications[i]!.NotificationDimensions![k].Id = 0;
            }
          }
        }
        // Highchart data for survey sub dialogues must be removed before sending survey for update
        this.localNotificationItem!.QuestionnaireNotifications[i]!.HighChartData = undefined;
        this.localNotificationItem!.QuestionnaireNotifications[i]!.RankingHighChartData = undefined;
        this.localNotificationItem!.QuestionnaireNotifications[i]!.ScalingHighChartData = undefined;
        this.localNotificationItem!.QuestionnaireNotifications[i]!.UserFeedback = undefined;
      }
    }

    if (this.localNotificationItem!.QuestionnaireNotifications! !== undefined && this.localNotificationItem!.QuestionnaireNotifications !== null) {
      for (let i = 0; i < this.localNotificationItem!.QuestionnaireNotifications.length; i++) {
        // for newly added survey sub dialogue we must makes its id = 0
        if (this.localNotificationItem!.QuestionnaireNotifications[i].IsCreateNewItemInSurvey) {
          let subItem = NotificationItem.createEmpty();
          // for reactivity breaking (otherwise "items with similar ids detected it might cause update error" will come)
          subItem = JSON.parse(JSON.stringify((this.localNotificationItem!.QuestionnaireNotifications[i])));
          subItem.Id = 0;
          // Check for if not pickcorrect
          if(subItem.NotificationTypeId !== NotificationEnum.PickCorrect) {
            subItem.ShowCorrectFeedbackToClients = false;
          }

          this.localNotificationItem!.QuestionnaireNotifications[i] = subItem;
        } else {
          let subItem = NotificationItem.createEmpty();
          // for reactivity breaking (otherwise "items with similar ids detected it might cause update error" will come)

          subItem = JSON.parse(JSON.stringify(NotificationItemSignalR.GetNotificationSignalRItem(this.localNotificationItem!.QuestionnaireNotifications[i])));
          // Check for if not pickcorrect
          if(subItem.NotificationTypeId !== NotificationEnum.PickCorrect) {
            subItem.ShowCorrectFeedbackToClients = false;
          }
          this.localNotificationItem!.QuestionnaireNotifications[i] = subItem;
        }
      }
    }
    // setting filter Id (0 is saved for "Published to eveyone") )

    this.localNotificationItem!.FilterId = this.localNotificationItem!.FilterId > 0 ? this.localNotificationItem!.FilterId : 0;

    if (!this.localNotificationItem!.IsIncludeImage) {
      this.localNotificationItem!.ImageFullUrl = '';
      this.localNotificationItem!.ImageThumbnailUrl = '';
    }
    Object.assign(
      this.notificationItemTobeSaved,
      this.localNotificationItem,
    );
  }
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Clear Error List
  private ClearErrorsList() {
    this.errors = [];
  }
  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  private FillValidationErrors() {
    const self = this;
    this.errors.forEach((item) => {
      self.validationErrorFields.push(item);
    });
  }
  private formatScheduleTime(pi: NotificationItem): string {
    const datescheduled: Date = moment(pi.ScheduleUTCDate).toDate();
    if (!this.isIEbrowser) {
    // setting date format for all browsers.
      return this.dateFormat.format(datescheduled).toUpperCase();
    } else {
    // setting date format for IE browser.
      const ieDateForamt = moment(datescheduled).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
      return ieDateForamt;
    }
  }
  private setScheduleDate(scheduleDate: Date) {
    if (scheduleDate) {
      this.localNotificationItem!.ScheduleDate = moment.utc(scheduleDate).toDate();
    } else {
      this.localNotificationItem!.ScheduleDate = null!;
    }
  }
  private ValidateDialogueValidation() {
    if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.SingleChoiceQuestion ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.MultipleChoiceQuestion ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.RankOptionItems ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements
    ) {
      // Validation check if number of options added are less than 2.
      if (this.localNotificationItem!.NotificationOptions!.length < 2) {
        this.errors.push(
          this.$t('Dialogue.Input.MinimumOptions').toString(),
        );
      } else {
        // If options satisfy validations then trim the text and set defaukt style of textboxes.
        const emptyOptions: NotificationOption[] = [];
        this.localNotificationItem!.NotificationOptions!.forEach((item) => {
          if (item.Text.trim() === '') {
            emptyOptions.push(item);
            item.defaultStyling = 'background: #fff;border: solid #FF0000 2px;';
          }
        });
        // Validation check for empty options
        if (emptyOptions.length > 0) {
          this.errors.push(this.$t('Dialogues.SelectedOptions').toString());
        }
      }
      // Validation checks for dimensions.
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements) {

        if (this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue <= this.localNotificationItem!.NotificationDimensions![0].NotificationDimensionValue) {
          this.errors.push(this.$t('Dialogue.HighValue').toString() + ' (' +

             this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue +
             ')' +
             this.$t('Dialogue.MustbeGraterthan').toString() + ' (' +
             this.localNotificationItem!.NotificationDimensions![0].NotificationDimensionValue + ')',
          );

        } else if (this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue > 10) {
          this.errors.push(this.$t('Dialogue.HighValue').toString() + ' (' +

             this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue +
              ') ' +
             this.$t('Dialogue.CanNotBe').toString() + '10.');
        }
      }
      // validation check if no correct option is selected in Pick correct Dialogue.
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect) {
        const objIndex = this.localNotificationItem!.NotificationOptions!.findIndex(
          (obj: NotificationOption) => obj.IsCorrectOption === true,
        );
        if (objIndex === -1) {
          this.errors.push(this.$t('Dialogue.ChooseOption').toString());
        }
      }
    } else if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.Survey) {
      // validation check if there is no sub item under survey yet (There must be atleast one item).
      if (this.localNotificationItem!.QuestionnaireNotifications !== null && this.localNotificationItem!.QuestionnaireNotifications !== undefined && this.localNotificationItem!.QuestionnaireNotifications.length === 0 ) {
        this.errors.push(this.$t('Dialogue.SurveyWarning').toString());
      }
    }
    if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.InformationMessage) {
      if(this.localNotificationItem!.IsAnyLinkedItemWithNotification && (this.localNotificationItem!.LinkableItems === null || this.localNotificationItem!.LinkableItems === undefined || this.localNotificationItem!.LinkableItems.EntityId === 0)){
        this.errors.push(this.$t('Error.PleaseLinkAnItemToProceed').toString());
      }
    }
  }
  private async SubmitForValidations(event: any, val: any) {
    const publishType = event.srcElement.innerHTML;
    val.$touch();
    this.validationErrorFields = [];
    this.ClearErrorsList();
    this.ClearMessagesList();
    this.ValidateDialogueValidation();
    const res = !await val.$validate();
    if(res) {
      if (val.localNotificationItem.Title!.$error === true) {
        this.validationErrorFields.push(this.$t('Title').toString());
        this.visibleValidationSummary = true;
        return;
      }
      if (this.errors.length) {
        this.FillValidationErrors();
        this.visibleValidationSummary = true;
        return;
      }
    }
    val.$reset();
    if (this.errors.length) {
      this.FillValidationErrors();
      this.visibleValidationSummary = true;
      return;
    }
    // Drop down item clicked (Publish later/ Publish now / Save)
    if (publishType === this.$t('Dialogue.PublishLater').toString()) {
      this.PublishLaterShowHide(true);
    } else if (publishType === this.$t('Dialogue.Save').toString()) {
      this.localNotificationItem!.PublishNow = 'false';
      this.InitializeSave();
    // Code commented for DEV-8687
    // } else if (publishType === this.$t('Publish').toString()) {
    //   this.localNotificationItem!.PublishNow = 'true';
    //   if (!this.localNotificationItem!.IsPublished && this.localNotificationItem!.Id !== 0) {
    //     this.MakeNotificationPublished(this.localNotificationItem!.Id,true,this.localNotificationItem!.IsSentPublish);
    //     this.localNotificationItem!.PublishedDateTime = moment(this.schedultedDate).toDate();
    //   } else {
    //     this.InitializeSave();
    //   }
    } else {
      this.localNotificationItem!.PublishNow = 'true';
      this.InitializeSave(); // Save Button in Published Notifications
    }
  }
  private Submit() {
    // No errors submitting.
    this.FillNotificationItemTobeSaved();
    const newnotif = NotificationItem.createEmpty();
    newnotif.Id = this.notificationItemTobeSaved.Id;
    newnotif.Title = this.notificationItemTobeSaved.Title;
    newnotif.Description = this.notificationItemTobeSaved.Description;
    newnotif.Folder = this.notificationItemTobeSaved.Folder;
    newnotif.IsPublished = this.notificationItemTobeSaved.IsPublished;
    newnotif.IsRespondOnlyOnce = this.notificationItemTobeSaved.IsRespondOnlyOnce;
    newnotif.EnableClientShowResults = this.notificationItemTobeSaved.EnableClientShowResults;
    newnotif.EnableResponseAnonymously = this.notificationItemTobeSaved.EnableResponseAnonymously;
    newnotif.Code = this.notificationItemTobeSaved.Code;
    newnotif.NotificationType = this.notificationItemTobeSaved.NotificationType;
    newnotif.NotificationTypeId = this.notificationItemTobeSaved.NotificationTypeId;
    newnotif.NotificationTypeDescription = this.notificationItemTobeSaved.NotificationTypeDescription;
    newnotif.NotificationOptions = this.notificationItemTobeSaved.NotificationOptions;
    newnotif.NotificationDimensions = this.notificationItemTobeSaved.NotificationDimensions;
    newnotif.IsSentPublish = this.notificationItemTobeSaved.IsSentPublish;
    newnotif.ImageFullUrl = this.notificationItemTobeSaved.ImageFullUrl;
    newnotif.ImageThumbnailUrl = this.notificationItemTobeSaved.ImageThumbnailUrl;
    newnotif.PublishNow = this.notificationItemTobeSaved.PublishNow;
    newnotif.ScheduleDate = this.notificationItemTobeSaved.ScheduleDate;
    newnotif.IsSurvey = this.notificationItemTobeSaved.IsSurvey;
    newnotif.QuestionnaireNotifications = this.notificationItemTobeSaved.QuestionnaireNotifications;
    newnotif.IsCreateNewItemInSurvey = this.notificationItemTobeSaved.IsCreateNewItemInSurvey;
    newnotif.FilterId = this.notificationItemTobeSaved.FilterId;
    newnotif.Filter = this.notificationItemTobeSaved.Filter;
    newnotif.PublishedDateTime = this.notificationItemTobeSaved.PublishedDateTime;
    newnotif.Timeoffset = new Date().getTimezoneOffset();
    newnotif.ShowCorrectFeedbackToClients = this.notificationItemTobeSaved.ShowCorrectFeedbackToClients;
    newnotif.SelectedChartTypeId = this.notificationItemTobeSaved.SelectedChartTypeId;
    if(newnotif.NotificationType === 'ScalingAction') {
      if(this.notificationItemTobeSaved.SelectedChartTypeId === 0 || this.notificationItemTobeSaved.SelectedChartTypeId === undefined) {
        if(newnotif.NotificationOptions!.length < 3) {
          newnotif.SelectedChartTypeId = ChartTypeEnum.SliderChart;
        } else {
          newnotif.SelectedChartTypeId = ChartTypeEnum.SpiderChart;
        }
      }
    }
    if (this.notificationItemTobeSaved.NotificationTypeId === NotificationEnum.InformationMessage && this.notificationItemTobeSaved.IsAnyLinkedItemWithNotification) {
      newnotif.LinkableItems = this.notificationItemTobeSaved.LinkableItems;
      newnotif.IsAnyLinkedItemWithNotification = this.notificationItemTobeSaved.IsAnyLinkedItemWithNotification;
    }
    if (!newnotif.IsSurvey && newnotif.NotificationTypeId !== NotificationEnum.Survey) {
      // Saving dialogues
      const signalObject = this.SendNotificationRequest(false);
      FetchData('/Notification/SaveOrUpdateNotification', JSON.stringify({
        detailsNotification: newnotif,
      }))
        .then((response: NotificationItem) => {
          Root.emit('set-notification-landing-page', true);
          this.SendNotificationRequestToQueue(
            signalObject,
            response,
          );
          this.UncheckAllCheckBoxesForcefully();
          setTimeout(() => {
            this.ClearMessagesList();
          }, 5000);
        })
        .catch(() => {
          this.showLoading = false;
        });
    } else { // saving updating Survey
      const signalmObject = this.SendNotificationRequestforSurvey(false);
      FetchData('/Notification/AddUdateQuestionaireNotification', JSON.stringify({
        response: JSON.stringify(newnotif),
      }))
        .then((response: NotificationItem) => {
          Root.emit('set-notification-landing-page', true);
          this.SendNotificationSurveyRequestToQueue(
            signalmObject,
            response,
          );
          this.UncheckAllCheckBoxesForcefully();
          setTimeout(() => {
            this.ClearMessagesList();
          }, 5000);
        })
        .catch(() => {
          this.showLoading = false;
        });
    }
  }
  // Delete Dialogue
  private DeleteNotification() {
    const signalObject = this.SendNotificationRequest(true);

    const notificationId: number = this.localNotificationItem!.Id;

    FetchData('/Notification/Delete', JSON.stringify({
      id: notificationId,
    }))
      .then((response) => {
        if (response.Success) {
          this.UncheckAllCheckBoxesForcefully();
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localNotificationItem!,
          );
          this.$emit('notification-item-delete-operation', this.localNotificationItem );
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Delete Survey
  private DeleteNotificationSurvey() {
    const signalObject = this.SendNotificationRequestforSurvey(true);

    const notificationId: number = this.localNotificationItem!.Id;

    FetchData('/Notification/Delete', JSON.stringify({
      id: notificationId,
    }))
      .then((response) => {
        if (response.Success) {
          // Successful delete
          this.SendNotificationSurveyRequestToQueue(
            signalObject,
            this.localNotificationItem!,
          );
          this.$emit(
            'notification-item-delete-operation',
            this.localNotificationItem );
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private GetFilterProperties() {
    // Get values for creating filter (Seg. Que/ Seg. Choices/ Interrest/ Groups)
    Root.emit('notificationDetailLoading', true);
    FetchData('/Notification/GetFilterProperties', '')
      .then((response) => {
        const copyViewModel = response;
        this.filterViewModel = copyViewModel;
        this.showLoading = false;
        Root.emit('notificationDetailLoading', false);
      })
      .catch(() => {
        Root.emit('notificationDetailLoading', false);
      });
  }
  private CancelScheduling() {
    // Cancel scheduling of the notification and make it Unpublished
    this.localNotificationItem!.ScheduleDate = null!;
    this.localNotificationItem!.IsScheduled = false;
    const signalObject = this.SendNotificationRequest(false);
    FetchData('/Notification/UnpublishNotification',
      JSON.stringify({ id: this.localNotificationItem!.Id }))
      .then((response) => {
        if (response.Success) {
          // Successfully unpublish
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localNotificationItem!,
          );
          // this.$emit(
          //   'notification-item-unpublish',
          //   this.localNotificationItem );
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  private MakeNotificationPublished(notificationId: number, isVisibletoClient: boolean, isSendPublish: boolean) {
    FetchData('/Notification/MakeVisible',
      JSON.stringify({ id: notificationId,
        isVisible: isVisibletoClient,
        sendpush: isSendPublish,
      }))
      .then((response) => {
        if (response) {
          this.localNotificationItem!.IsPublished = true;
          const date = response.selectedDate;
          const timezone = response.timeZone;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const datepublished = moment(date).zone(timezone);
          this.localNotificationItem!.PublishedDateTime = moment(datepublished).toDate();
          this.InitializeSave();
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  private InitializeSave() {
    if (this.errors.length === 0 && this.validationErrorFields.length === 0 && !this.showPublishLater) {
      this.Submit();
    }
  }
  private SendNotificationRequest(
    isDelete: boolean,
  ): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    Root.emit('notifications-uncheck-select-all');
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (isDelete) {
      signalObject = signalR.SingleDeleteNotificationSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localNotificationItem!.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localNotificationItem!.Id === 0) {
        // get SignalR model for Create new action
        signalObject = signalR.SingleCreateNotificationItemSignalRModelRequest;
      } else {
        // get SignalR model for update existing action
        signalObject = signalR.SingleUpdateNotificationItemSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localNotificationItem!.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString()),
      }).toString();
    }
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localNotificationItem!.Title,
    });
    signalR.DisableNotificationItemsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    notificatonItem: NotificationItem,
  ) {
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(notificatonItem.Id);
      signalObject.SelectedIds = arr;
    }
    // signalObject.JSONData = JSON.stringify({selectedName: this.localNotificationItem!.Title});
    signalObject.JSONData = signalR.GetNotificationItemToSendToSignalR(notificatonItem);
    signalR.PerformNotificationSingleOperation(signalObject);
  }

  // Signal R Survey
  private SendNotificationRequestforSurvey(
    isDeleteSurvey: boolean,
  ): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    Root.emit('notifications-uncheck-select-all');
    const signalR: NotificationSurveySignalRModel = new NotificationSurveySignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (isDeleteSurvey) {
      signalObject = signalR.SingleDeleteNotificationSurveyItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localNotificationItem!.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localNotificationItem!.Id === 0) {
        // get SignalR model for Create new action
        signalObject = signalR.SingleCreateNotificationSurveyItemSignalRModelRequest;
      } else {
        // get SignalR model for update existing action
        signalObject = signalR.SingleUpdateNotificationSurveyItemSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localNotificationItem!.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString()),
      }).toString();
    }
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localNotificationItem!.Title,
    });
    signalR.DisableNotificationSurveyItemAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationSurveyRequestToQueue(
    signalObject: SignalRModelRequest,
    notificatonItem: NotificationItem,
  ) {
    const signalR: NotificationSurveySignalRModel = new NotificationSurveySignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(notificatonItem.Id);
      signalObject.SelectedIds = arr;
    }
    // signalObject.JSONData = JSON.stringify({selectedName: this.localNotificationItem!.Title});
    signalObject.JSONData = signalR.GetNotificationItemToSendToSignalR(notificatonItem);
    signalR.PerformNotificationSingleOperation(signalObject);
  }
  private GetIsPublished(val: NotificationItem) {
    return val.IsPublished === false && val.ScheduleDate !== null && val.Id > 0 ? true : false;
  }

  private SetIsSentPublishInLocal(IsSentPublish: boolean) {
    this.localNotificationItem!.IsSentPublish = IsSentPublish;
  }

  private UnpublishedNotification() {
    this.showVueModalPopUp = true;
  }

  private SaveVueModelPopUp() {
    this.UnpublishedNotificationItem();
  }

  private CloseVueModelPopup() {
    this.showVueModalPopUp = false;
  }

  private UnpublishedNotificationItem(){
    this.localNotificationItem!.ScheduleDate = null!;
    this.localNotificationItem!.IsScheduled = false;
    this.localNotificationItem!.IsPublished = false;
    const signalObject = this.SendNotificationRequest(false);
    FetchData('/Notification/UnpublishNotificationItem',
      JSON.stringify({ id: this.localNotificationItem!.Id }))
      .then((response) => {
        if (response.Success) {
          // Successfully unpublish
          Root.emit('set-notification-landing-page', true);
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localNotificationItem!,
          );
        }
      })
      .catch(() => {
        // Log error if required
      });
    this.showVueModalPopUp = false;
  }

  @Watch('selectedNotificationItem', {deep: true})
  private onNotificationItemPropertyChange(
    val: NotificationItem,
    oldVal: NotificationItem,
  ) {
    if(this.localNotificationItem !== null && this.localNotificationItem !== undefined) {
      this.localNotificationItem.IsScheduled = this.GetIsPublished(val);
    }
    // hide validation summary when switching the Dialogue
    this.visibleValidationSummary = false;
    // this.IsEditControllersVisible = true;
    const currentNotificationItem = NotificationItem.prototype.SetNotificationObject(
      val,
    );
    const previousNotificationItem = NotificationItem.prototype.SetNotificationObject(
      oldVal,
    );
    /* Compare object works only if values to be campaired are either both null or both are having values.
    if one of the value is null and other one has the value the CompareObject methos will fail. So to ensure that
    it works properly, we are using null check on the values that could possibly through such error*/
    currentNotificationItem.Folder = currentNotificationItem.Folder === null ? '' : currentNotificationItem.Folder;
    previousNotificationItem.Folder = previousNotificationItem.Folder === null ? '' : previousNotificationItem.Folder;
    currentNotificationItem.Code = currentNotificationItem.Code === null ? '' : currentNotificationItem.Code;
    previousNotificationItem.Code = previousNotificationItem.Code === null ? '' : previousNotificationItem.Code;

    currentNotificationItem.ScheduleDate = currentNotificationItem.ScheduleDate === null ? '' : currentNotificationItem.ScheduleDate;

    previousNotificationItem.ScheduleDate = previousNotificationItem.ScheduleDate === null ? '' : previousNotificationItem.ScheduleDate;
    if (
      !Common.prototype.CompareObjects(
        currentNotificationItem,
        previousNotificationItem,
      )
    ) {
      // hide validation summary when switching the program
      this.visibleValidationSummary = false;
      this.updateInformationInLocalCustomCategoryItem();
      if (this.localNotificationItem !== null && this.localNotificationItem !== undefined && val.NotificationTypeId === NotificationEnum.Survey) {
        this.localNotificationItem.IsSurvey = true;
        // bug #3745
        // this.selectedNotificationItem!.QuestionnaireNotifications = undefined;
        // this.localNotificationItem!.QuestionnaireNotifications = val.QuestionnaireNotifications;
        if(this.localNotificationItem.QuestionnaireNotifications !== null && this.localNotificationItem.QuestionnaireNotifications !== undefined) {
          this.oldQuestionarieValue = this.localNotificationItem.QuestionnaireNotifications;
        } else {
          this.localNotificationItem.QuestionnaireNotifications = this.oldQuestionarieValue;
        }
      }
    }
  }
  private NotificationDiscussSelectedShowHide(val: boolean) {
    this.showDiscussSelected = val;
  }
}
export default toNative(NotificationParentEditPane);

