import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d7ab621"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "div-table border-radius-10"
}
const _hoisted_2 = { class: "options-table" }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = {
  scope: "col",
  class: "width60 table-head"
}
const _hoisted_5 = {
  scope: "col",
  class: "width20 table-head"
}
const _hoisted_6 = {
  scope: "col",
  class: "width20 table-head"
}
const _hoisted_7 = { class: "table-body" }
const _hoisted_8 = {
  key: 0,
  class: "empty-table-row"
}
const _hoisted_9 = {
  class: "empty-table-text",
  colspan: "3"
}
const _hoisted_10 = { class: "table-data" }
const _hoisted_11 = { class: "table-data" }
const _hoisted_12 = { class: "table-data" }
const _hoisted_13 = {
  key: 1,
  class: "div-table border-radius-10"
}
const _hoisted_14 = { class: "options-table" }
const _hoisted_15 = { class: "table-header" }
const _hoisted_16 = {
  class: "table-head width85",
  scope: "col"
}
const _hoisted_17 = {
  class: "table-head width15",
  scope: "col"
}
const _hoisted_18 = { class: "table-body" }
const _hoisted_19 = {
  key: 0,
  class: "empty-table-row"
}
const _hoisted_20 = {
  class: "empty-table-text",
  colspan: "2"
}
const _hoisted_21 = { class: "table-data" }
const _hoisted_22 = { class: "table-data" }
const _hoisted_23 = {
  key: 2,
  class: "div-table border-radius-10"
}
const _hoisted_24 = { class: "options-table" }
const _hoisted_25 = { class: "table-header" }
const _hoisted_26 = {
  class: "table-head width80",
  scope: "col"
}
const _hoisted_27 = {
  class: "table-head width20",
  scope: "col"
}
const _hoisted_28 = { class: "table-body" }
const _hoisted_29 = {
  key: 0,
  class: "empty-table-row"
}
const _hoisted_30 = {
  class: "empty-table-text",
  colspan: "2"
}
const _hoisted_31 = { class: "table-data" }
const _hoisted_32 = { class: "table-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.notificationTypeId == _ctx.notificationEnum.SingleChoiceQuestion ||
        _ctx.notificationTypeId == _ctx.notificationEnum.MultipleChoiceQuestion ||
        _ctx.notificationTypeId == _ctx.notificationEnum.PickCorrect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("table", _hoisted_2, [
            _createElementVNode("thead", _hoisted_3, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.Option")), 1),
                _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.TotalVotes")), 1),
                _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.PercentVotes")), 1)
              ])
            ]),
            _createElementVNode("tbody", _hoisted_7, [
              (_ctx.totalUsersFeedbacksCount === 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t("NoDataAvailableYet")), 1)
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.localNotificationItem.HighChartData.Results, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: index,
                      class: "table-row"
                    }, [
                      _createElementVNode("td", _hoisted_10, _toDisplayString(item.Option), 1),
                      _createElementVNode("td", _hoisted_11, _toDisplayString(item.NumberOfVotes), 1),
                      _createElementVNode("td", _hoisted_12, _toDisplayString(item.PercentageOfCastVotes + ' %'), 1)
                    ]))
                  }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.notificationTypeId == _ctx.notificationEnum.RankOptionItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("table", _hoisted_14, [
            _createElementVNode("thead", _hoisted_15, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.Option")), 1),
                _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.Rank")), 1)
              ])
            ]),
            _createElementVNode("tbody", _hoisted_18, [
              (_ctx.totalUsersFeedbacksCount === 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [
                    _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.$t("NoDataAvailableYet")), 1)
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.localNotificationItem.RankingHighChartData.Results, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: index,
                      class: "table-row"
                    }, [
                      _createElementVNode("td", _hoisted_21, _toDisplayString(item.Text), 1),
                      _createElementVNode("td", _hoisted_22, _toDisplayString(item.FinalRank), 1)
                    ]))
                  }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.notificationTypeId == _ctx.notificationEnum.ScaleStatements)
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("table", _hoisted_24, [
            _createElementVNode("thead", _hoisted_25, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.Statements")), 1),
                _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.$t("Dialogue.OptionsTable.AvgRating")), 1)
              ])
            ]),
            _createElementVNode("tbody", _hoisted_28, [
              (_ctx.totalUsersFeedbacksCount === 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_29, [
                    _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.$t("NoDataAvailableYet")), 1)
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.scaleStatmentInsights, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: index,
                      class: "table-row"
                    }, [
                      _createElementVNode("td", _hoisted_31, _toDisplayString(item.OptionText), 1),
                      _createElementVNode("td", _hoisted_32, _toDisplayString(item.DataResult), 1)
                    ]))
                  }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}