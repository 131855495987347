import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex description" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "padding-1 margin-left-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        id: "sendPushOnPublish",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localIsSentPublish) = $event)),
        type: "checkbox",
        class: "margin-left25",
        disabled: _ctx.localIsPublished,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change()))
      }, null, 40, _hoisted_2), [
        [_vModelCheckbox, _ctx.localIsSentPublish]
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Dialogue.SendPushOnPublish")), 1)
    ])
  ]))
}