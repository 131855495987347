
import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { PowerPointToken } from '@/entities/Notification/NotificationResults';
import moment from 'moment';
import { FetchData } from '@/main';
@Component({
  components: {
    VueModalPopUp,
  },
})
class GenerateToken extends Vue {
  @Prop() notificationId?: number;
  @Prop() powerPointToken?: PowerPointToken;

  private localPowerPointTokenToken = PowerPointToken.createEmpty();
  private greenTickIcon = require('@/assets/Images/green-tick.svg');
  private copyIcon = require('@/assets/Images/copy-icon.svg');
  private redCrossIcon = require('@/assets/Images/red-cross.svg');
  private visibleRevokeToken = false;
  private visibleTokenRevoked = false;
  private visibleTokenGenerated = false;
  private localNotificationItemId = 0;
  private showModalPopup = false;
  private revokeTokenModelPopup: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private hideAcknowledgedMessageTime = 2000;
  private showKeyCopiedMessage = false;
  private expirationDate = '';
  private visibleTokenExtended = false;
  private visibleTokenExpired = false;
  private mounted(){
    if (this.notificationId !== null && this.notificationId !== undefined) {
      this.localNotificationItemId = this.notificationId;
    }
    if (this.powerPointToken !== null && this.powerPointToken !== undefined) {
      this.AssignDataInLocalObject(this.powerPointToken);
      this.visibleTokenGenerated = true;
      this.visibleRevokeToken = true;
      if ( moment(this.localPowerPointTokenToken.ExpirationDate.toUTCString()) < moment(new Date().toUTCString())){
        this.visibleTokenExpired = true;
      }
    }
  }

  private GenerateUniqueToken(id: number) {
    if(!this.visibleTokenRevoked) {
      FetchData('/Notification/GenerateUniqueToken', JSON.stringify({
        notificationId: id,
      }))
        .then((response) => {
          {
            this.localPowerPointTokenToken.ExpirationDate = response.powerPointToken.ExpirationDate;
            this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
            this.localPowerPointTokenToken.Token = response.powerPointToken.Token;
            this.localPowerPointTokenToken.Id = response.powerPointToken.Id;
            this.visibleRevokeToken = true;
            setTimeout(() => {
              this.visibleTokenGenerated = true;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenGenerated = false;
          }
        });
    }
  }
  private FormatDate(fullDate: Date) {
    return moment(fullDate).utc().format('MMM DD, YYYY');
  }
  private RevokeUniqueTokenAPI(id: number) {
    FetchData('/Notification/RevokeToken', JSON.stringify({
      notificationId: id,
    }))
      .then((response) => {
        {
          if (response.Success === true) {
            this.localPowerPointTokenToken.ExpirationDate = new Date();
            this.localPowerPointTokenToken.Token = '';
            this.visibleRevokeToken = false;
            setTimeout(() => {
              this.visibleTokenRevoked = false;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenRevoked = true;
          }
        }
      });
  }

  private ExtendUniqueTokenDate(id: number) {
    FetchData('/Notification/ExtendUniqueToken', JSON.stringify({
      tokenId: id,
    }))
      .then((response) => {
        {
          if (response.Success === true) {
            setTimeout(() => {
              this.visibleTokenExtended = false;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenExtended = true;
            this.localPowerPointTokenToken.ExpirationDate =  response.expirationDate;
            this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
          }
        }
      });
  }

  //  Close Model Popup
  private CloseModelPopup() {
    this.showModalPopup = false;
  }

  private SaveModelPopUp() {
    this.showModalPopup = false;
    this.RevokeUniqueTokenAPI(this.localNotificationItemId);
  }

  private RevokeUniqueToken() {
    this.showModalPopup = true;
    this.revokeTokenModelPopup = new VueModalPopUpViewModel(
      this.$t('Notification.PowerPointIntegration.RevokeToken').toString(),
      this.$t('Notification.PowerPointIntegration.RevokeTokenDescription')
        .toString(),
      false,
      '',
      this.$t('Notification.PowerPointIntegration.DontRevokeToken').toString(),
      this.$t('Notification.PowerPointIntegration.Revoke').toString(),
    );
  }
  private copyLinkToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.showKeyCopiedMessage = true;
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showKeyCopiedMessage) {
      setTimeout(() => {
        this.showKeyCopiedMessage = false;
      }, this.hideAcknowledgedMessageTime);
    }
    document.body.removeChild(selBox);
  }

  private AssignDataInLocalObject(val: PowerPointToken){
    this.localPowerPointTokenToken.Id = val.Id;
    this.localPowerPointTokenToken.Token = val.Token;
    this.localPowerPointTokenToken.ExpirationDate = val.ExpirationDate;
    this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
  }

  @Watch('powerPointToken')
  private OnChange(val: PowerPointToken) {
    if (val !== null && val !== undefined) {
      this.AssignDataInLocalObject(val);
      if (this.localPowerPointTokenToken.Id !== null && this.localPowerPointTokenToken.Id !== undefined) {
        this.visibleTokenGenerated = true;
        this.visibleRevokeToken = true;
        if ( moment(this.localPowerPointTokenToken.ExpirationDate.toUTCString()) < moment(new Date().toUTCString())){
          this.visibleTokenExpired = true;
        }
      }
    }
  }
}
export default toNative(GenerateToken);
