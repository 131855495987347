
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { HighChartSeriesPointFormat } from '@/entities/Notification/NotificationResults';
import HighchartBarChart from '@/components/Common/HighchartBarChart.vue';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import {NotificationChartTypeForDialoguesEnumParse, NotificationChartTypeForSurveyDialoguesEnumParse } from '@/entities/Notification/NotificationResultsSettings';
import HighchartPieChart from '@/components/Common/HighchartPieChart.vue';
import HighchartSpiderChart from '@/components/Common/HighchartSpiderChart.vue';
import HighchartWordCloud from '@/components/Common/HighchartWordCloud.vue';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import HighchartHelper from '@/helper/HighchartHelper';
import NotificationTitleAndViewLiveResponses from './NotificationTitleAndViewLiveResponses.vue';
import { Root } from '@/main';

@Component({
  components: {
    HighchartBarChart,
    HighchartPieChart,
    HighchartSpiderChart,
    HighchartWordCloud,
    NotificationTitleAndViewLiveResponses,
  },
})
class NotificationHighCharts extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;

  private localNotificationItem: NotificationItem | null = null;
  private notificationTypeId = -1;
  private chartType = '';
  private chartCategories: string[] = [];
  private chartData: any[] = [];
  private commonChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private externalLinkIcon = require('@/assets/Images/open-in-new-tab-icon-blue.svg');
  private totalUsersFeedbacksCount = 0;
  public get chartTypeEnum(): typeof ChartTypeEnum {
    return ChartTypeEnum;
  }
  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem);
    this.SetTotalUserFeedbackCountAndNotifocationTypeId();
    this.GetChartType();
    this.SetCommonChartData();
    // this updates the data and empty previous data such that variable only have the new data not the combination of both
    Root.on('redraw-chart', () => {
      this.chartCategories = [];
      this.chartData = [];
      this.GetChartType();
      this.SetCommonChartData();
    });
  }
  private IsBarChart() {
    return (this.localNotificationItem!.SelectedChartTypeId === ChartTypeEnum.BarChart || this.localNotificationItem!.SelectedChartTypeId === ChartTypeEnum.RankingBarChart ||this.localNotificationItem!.SelectedChartTypeId === ChartTypeEnum.SliderChart);
  }
  // this sets and updates the values of totalUsersFeedbacksCount which helps in determining the total number of user feedback left/available to show the apt data i.e, chart or the empty response case and NotificationTypeId which helps in determining the type of chart corresponding to the type of dialogue item
  private SetTotalUserFeedbackCountAndNotifocationTypeId() {
    this.notificationTypeId = this.localNotificationItem!.NotificationTypeId;
    if(this.localNotificationItem!.NotificationOptions !== undefined && this.localNotificationItem!.UserFeedback !==undefined) {
      this.totalUsersFeedbacksCount = this.localNotificationItem!.UserFeedback.length;
    }
  }
  // this will set the common data  that can be further transfered to chart components
  private SetCommonChartData() {
    this.GetChartData();
    this.commonChartData = CommonHighchartData.createEmpty();
    if(this.localNotificationItem!.NotificationDimensions!== undefined) {
      const maxIndex = this.localNotificationItem!.NotificationDimensions.length;
      // this helps in setting the value for commonchartdata which will be sent further to draw the chart according to the type of chart
      switch (this.chartType) {
      case HighchartHelper.ScalingChart:
        this.commonChartData = CommonHighchartData.SetScalingChartData(this.chartCategories,this.chartData,this.chartType,this.localNotificationItem!.NotificationDimensions[maxIndex - 1].NotificationDimensionValue,this.localNotificationItem!.NotificationDimensions[0].NotificationDimensionValue);
        break;
      case HighchartHelper.RankingChart:
        this.commonChartData = CommonHighchartData.SetRankingChartData(this.chartCategories,this.chartData,this.chartType);
        break;
      default:
        this.commonChartData = CommonHighchartData.SetBarChartData(this.chartCategories,this.chartData,this.chartType);
        break;
      }
    }
  }
  private ChangeSelectedChart(id: number) {
    this.localNotificationItem!.SelectedChartTypeId = id;
  }
  private GetChartData() {
    if (this.selectedNotificationItem !== null && this.selectedNotificationItem !== undefined) {
      // Set data for SCQ/MCQ/ Pick correct
      if ((this.chartType === 'bar' || this.chartType === 'pie') && this.localNotificationItem!.HighChartData !== null && this.localNotificationItem!.HighChartData !== undefined) {
        this.localNotificationItem!.HighChartData.Results!.forEach((element) => {
          this.chartCategories.push(element.Option);
          this.chartData.push({percentage: element.PercentageOfCastVotes,votes:element.NumberOfVotes});
        });
      } else if (this.chartType === 'rank' && this.localNotificationItem!.RankingHighChartData!== null && this.localNotificationItem!.RankingHighChartData!== undefined) {
        // Set chart data for Rank dialogue
        this.localNotificationItem!.RankingHighChartData.Results!.forEach((element) => {
          this.chartCategories.push(element.Text);
          /* HighChartSeriesPointFormat is used where we have to display three data on
        chart (y / Data result/ percentageVotes). */
          const RnkData = HighChartSeriesPointFormat.createEmpty();
          RnkData.y = element.Score;
          RnkData.DataResult = element.FinalRank;
          this.chartData.push(RnkData);
        });
      } else {
        if (this.localNotificationItem !== null && this.localNotificationItem.ScalingHighChartData !== undefined &&  this.localNotificationItem.ScalingHighChartData !== null) {
        // Set chart data for Scale correct
          this.localNotificationItem.ScalingHighChartData.Options!.forEach((element) => {
            this.chartCategories.push(element.Text);
          });
        }
        if ( this.localNotificationItem !== null && this.localNotificationItem.ScalingHighChartData !== null &&  this.localNotificationItem.ScalingHighChartData !== undefined) {
          this.localNotificationItem.ScalingHighChartData.DataResults!.forEach((element) => {
            this.chartData.push(element);
          });
        }
      }
    }
  }
  // this return the type of chart according to the notification type id
  private GetChartType() {
    if (!this.localNotificationItem!.IsSurvey) { // Get chart type for dialogues

      this.chartType = NotificationChartTypeForDialoguesEnumParse.GetIChartTypeForDialogues(this.localNotificationItem!.NotificationTypeId);
    } else {  // Get chart type for survey sub items

      this.chartType = NotificationChartTypeForSurveyDialoguesEnumParse.GetIChartTypeForDialoguesSurvey(this.localNotificationItem!.NotificationTypeId);
    }
  }
  @Watch('selectedNotificationItem')
  private onItemPropertyChange(val: NotificationItem) {
    this.localNotificationItem = val;
    this.SetTotalUserFeedbackCountAndNotifocationTypeId();
  }
  @Watch('commonChartData')
  private CheckChangeInBarData(val: CommonHighchartData) {
    this.commonChartData = val;
  }
}
export default toNative(NotificationHighCharts);
