
import { StoreHelper } from '@/store/StoreHelper';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import NotificationEnum from '@/enums/NotificationEnum';
import { FetchData, Root, Store } from '@/main';
@Component({
  components: {
  },
})
class NotificationTitleAndViewLiveResponses extends Vue {
  @Prop() private selectedNotificationItem!: NotificationItem;
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private externalLinkIcon = require('@/assets/Images/open-in-new-tab-icon-blue.svg');
  private dialogueSignalRURL = process.env.VUE_APP_DIALOGUESSIGNALRRESPONSE;
  private title = '';
  private showLiveResponses = true;
  private liveResponsesUrl = '';

  private mounted(){
    this.localNotificationItem = Object.assign({},this.selectedNotificationItem);
    this.title = this.localNotificationItem.Title;
    if(this.localNotificationItem.IsSurvey) {
      this.showLiveResponses = false;
    } else {
      this.GetLiveResponsesUrl(this.localNotificationItem.Id);
    }
  }
  private IsDiscussedSelectedVisible() {
    return (this.showLiveResponses && this.localNotificationItem.NotificationFeedbacks > 0 && (this.localNotificationItem.NotificationTypeId === NotificationEnum.MultipleRespponseTextFeedback || this.localNotificationItem.NotificationTypeId === NotificationEnum.SingleRespponseTextFeedback));
  }
  private GetLiveResponsesUrl(notificationId: number) {
    FetchData('/Notification/GetResultUrl', JSON.stringify({
      ids: notificationId.toString(),
    }))
      .then((responsedata) => {
        let url = '';
        if (this.localNotificationItem.SelectedChartTypeId === ChartTypeEnum.WordCloud) {
          url = '/api/GetResultsAdmin/Dialogue/'+ responsedata.encrypUrl + '/wc';
        } else if (this.localNotificationItem.SelectedChartTypeId === ChartTypeEnum.TextListing) {
          url = '/api/GetResultsAdmin/Dialogue/'+ responsedata.encrypUrl + '/lf';
        } else {
          url = '/api/GetResultsAdmin/Dialogue/'+ responsedata.encrypUrl;
        }
        this.liveResponsesUrl = this.dialogueSignalRURL + url;
        return this.liveResponsesUrl;
      });
  }
  private clickOnDiscussSelected() {
    Root.emit('notification-show-discuss-selected-pane', true);
  }
}
export default toNative(NotificationTitleAndViewLiveResponses);
