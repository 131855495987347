import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inline-flex100 align-items-center" }
const _hoisted_2 = { class: "main-title-width" }
const _hoisted_3 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "inline-flex" }
const _hoisted_7 = { class: "margin-top-30" }
const _hoisted_8 = { class: "table table-striped" }
const _hoisted_9 = ["id", "value"]
const _hoisted_10 = { class: "padding-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
        }, _toDisplayString(_ctx.$t("Button.Close")), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isMobileView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("Dialogues.SelectedDiscussMobile")), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Dialogues.SelectedDiscuss")), 1)
            ]))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-size-default",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Display()))
        }, _toDisplayString(_ctx.$t("Button.Display")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("table", _hoisted_8, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userFeedbacks, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.Id
            }, [
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  id: 'feedback_' + item.Id,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
                  type: "checkbox",
                  value: item
                }, null, 8, _hoisted_9), [
                  [_vModelCheckbox, _ctx.selectedItems]
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(item.Value), 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}