import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2581c5fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-title-responses" }
const _hoisted_2 = { class: "div-highcharts-title" }
const _hoisted_3 = {
  key: 0,
  class: "div-view-live-respones cursor-pointer"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "div-discuss-selected margin-bottom-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.showLiveResponses)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.HighCharts.ViewResponses")), 1),
            _createElementVNode("img", {
              class: "margin-left-10 margin-bottom-2",
              src: _ctx.externalLinkIcon
            }, null, 8, _hoisted_4),
            _createElementVNode("a", {
              class: "anchor-view-live-responses",
              href: _ctx.liveResponsesUrl,
              target: "_blank"
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.IsDiscussedSelectedVisible())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnDiscussSelected()))
          }, _toDisplayString(_ctx.$t("Dialogue.HighCharts.DiscussSelected")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}