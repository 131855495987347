
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import { NotificationUserResponseOptionsItem } from '@/entities/Notification/NotificationUserResponseOptionsItem';
import NotificationHeaderPane from '@/components/Notification/NotificationCommon/NotificationHeaderPane.vue';
import NotificationUserResponseOptions from '@/components/Notification/NotificationCommon/NotificationUserResponseOptions.vue';
import NotificationFooterPane from '@/components/Notification/NotificationCommon/NotificationFooterPane.vue';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import NotificationSelectionType from './NotificationSelectionType.vue';
import { NotificationSelectionTypeModel } from '@/entities/Notification/NotificationSelectionTypeModel';
import { Root } from '@/main';

@Component({
  components: {
    loading,
    ModalPopup,
    NotificationHeaderPane,
    NotificationUserResponseOptions,
    NotificationFooterPane,
    NotificationSelectionType,
  },
})
class NotificationSingleResponseTextFeedbackEditPane extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];

  private localNotificationItem: NotificationItem | null = null;
  private userResponseoptions: NotificationUserResponseOptionsItem = NotificationUserResponseOptionsItem.createEmpty();
  private wordCloudImg = require('@/assets/Images/Cloud.svg');
  private listingImg = require('@/assets/Images/List.svg');
  private selectChartType: NotificationSelectionTypeModel[] = [];

  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.showUserResponseOptions();
  }
  private mounted() {
    // setting data for selected chart type to show the chart options according to which chart will be drawn
    this.selectChartType.push({chartTypeId:ChartTypeEnum.TextListing,chartName:this.$t('Notification.DialogueResult.Listview').toString(),chartIcon: this.listingImg});
    this.selectChartType.push({chartTypeId:ChartTypeEnum.WordCloud,chartName:this.$t('Notification.DialogueResult.Wordcloud').toString(),chartIcon: this.wordCloudImg});
    if(this.localNotificationItem!.SelectedChartTypeId === undefined || this.localNotificationItem!.SelectedChartTypeId === null || this.localNotificationItem!.SelectedChartTypeId === 0) {
      // setting default selected chart type
      this.localNotificationItem!.SelectedChartTypeId = ChartTypeEnum.TextListing;
    }
  }
  private SetlocalNotificationHeaderProperties(notificationItem: NotificationItem) {
  // Set following Notification header properties
    this.localNotificationItem!.Title = notificationItem.Title;
    this.localNotificationItem!.Description = notificationItem.Description;
    this.localNotificationItem!.IsSentPublish = notificationItem.IsSentPublish;
    this.localNotificationItem!.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    this.localNotificationItem!.PublishedDateTime = notificationItem.PublishedDateTime;
    this.localNotificationItem!.ScheduleDate = notificationItem.ScheduleDate;
    this.localNotificationItem!.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
  }
  private showUserResponseOptions() {
    // Setting "Allow response only once"
    this.userResponseoptions.OnlyAllowResponseValue = this.localNotificationItem!.IsRespondOnlyOnce!;
    // Setting "Show results in app"
    this.userResponseoptions.ShowAnswersInPhoneValue = this.localNotificationItem!.EnableClientShowResults!;
    // Setting "Make participane anonymous while answering"
    this.userResponseoptions.MakeUserAnonumousValue = this.localNotificationItem!.EnableResponseAnonymously!;
    // Making "Make participane anonymous while answering" disabled if item is published
    if (this.localNotificationItem!.IsPublished) {
      this.userResponseoptions.MakeUserAnonumousDisabled = true;
    }
  }
  private setUserResponseOptions(event: any) {
  /* handeling any changes made in checkboxes "Allow response only once", "Show answers in App",
    "Make participane anonymous while answering"*/
    const newUserResponseItem: NotificationUserResponseOptionsItem = event;
    this.localNotificationItem!.IsRespondOnlyOnce = newUserResponseItem.OnlyAllowResponseValue;
    this.localNotificationItem!.EnableClientShowResults = newUserResponseItem.ShowAnswersInPhoneValue;
    this.localNotificationItem!.EnableResponseAnonymously = newUserResponseItem.MakeUserAnonumousValue;
  }
  private SetSelectedFolder(folder: string) {
    this.localNotificationItem!.Folder = folder;
  }
  private SetAccessCode(accessCode: string) {
    this.localNotificationItem!.Code = accessCode;
  }
  private SetFilter(filterId: number) {
  // Set filter Id and Filter name for Saving
    this.localNotificationItem!.FilterId = filterId;
    if (filterId > 0 ) {
      const objindex = this.filters!.findIndex(
        (obj: NotificationFilter) => obj.Id === filterId,
      );
      if (objindex > -1) {
        this.localNotificationItem!.Filter = this.filters![objindex].FilterName;
      }
    }
  }
  private ChangeSelectedChartTypeId(id: number) {
    this.localNotificationItem!.SelectedChartTypeId = id;
  }
  @Watch('selectedNotificationItem')
  private onItemPropertyChange(val: NotificationItem) {
  // Updating local Notification object
    this.localNotificationItem = val;
    this.showUserResponseOptions();
  }
  @Watch('localNotificationItem', {deep: true})
  private onlocalItemPropertyChange(newval: NotificationItem) {
  // any edits made in Local Notification Object must be reflected in NotificationEditItemPane
    Root.emit('notification-edited', newval);
  }
}
export default toNative(NotificationSingleResponseTextFeedbackEditPane);
