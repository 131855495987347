
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import NotificationEnum from '@/enums/NotificationEnum';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import Common from '@/helper/Common';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { NotificationTypeDescriptionEnumParse, NotificationTypeEnumParse } from '@/enums/NotificationEnum';
import NotificationSingleChoiceQuestionEditPane from '@/components/Notification/EditNotification/NotificationSingleChoiceQuestionEditPane.vue';
import NotificationInformationMessageEditPane from '@/components/Notification/EditNotification/NotificationInformationMessageEditPane.vue';
import NotificationMultipleChoiceQuestionEditPane from '@/components/Notification/EditNotification/NotificationMultipleChoiceQuestionEditPane.vue';
import NotificationSingleResponseTextFeedbackEditPane from '@/components/Notification/EditNotification/NotificationSingleResponseTextFeedbackEditPane.vue';
import NotificationPickCorrectAnswerEditPane from '@/components/Notification/EditNotification/NotificationPickCorrectAnswerEditPane.vue';
import NotificationRankOptionItemEditPane from '@/components/Notification/EditNotification/NotificationRankOptionItemEditPane.vue';
import NotificationScaleStatementEditPane from '@/components/Notification/EditNotification/NotificationScaleStatementEditPane.vue';
import NotificationResultsDetails from '@/components/Notification/NotificationResults/NotificationResultsDetails.vue';
import { Root, t } from '@/main';
import { reactive } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import RemoveNotificationFeedback from '@/entities/Emits/Notification/RemoveNotificationFeedback';
@Component({
  components: {
    ValidationSummary,
    NotificationSingleChoiceQuestionEditPane,
    NotificationInformationMessageEditPane,
    NotificationMultipleChoiceQuestionEditPane,
    NotificationSingleResponseTextFeedbackEditPane,
    NotificationPickCorrectAnswerEditPane,
    NotificationRankOptionItemEditPane,
    NotificationScaleStatementEditPane,
    NotificationResultsDetails,
  },
})
class NotificationSurveyNotificationItemEditPane extends Vue {
// Properties
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private landAtResults?: boolean;
  @Prop() private folders?: string[];
  @Prop() private notificationItemsList?: NotificationItem[];

  private localNotificationItem: NotificationItem | null = null;
  private notificationTypeId = -1;
  private isMobileView = false;
  private createAnother = false;
  private showResults = false;
  private NavigatorButtonsVisible = true;
  private isNextButtonsVisible = true;
  private isPreviousButtonsVisible = true;
  private uniqueIdArray: number[] = [];
  private showAddedMessage?: boolean = false;
  private itemaddedFadeoutTime = 2500;
  private addedItemTitle = '';
  private numberofDefualtIOptions = 2;
  private state = reactive({
    localNotificationItem: null as NotificationItem | null,
  });

  private rules = {
    localNotificationItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }

  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private notificationItemTobeSaved: NotificationItem = NotificationItem.createEmpty();
  private showLoading = false;
  private loaderBorderColor = '';
  private errors: string[] = [];
  private messages: string[] = [];

  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem);
    this.state.localNotificationItem = this.localNotificationItem;
    this.showResults = this.landAtResults!;
    this.NavigatorButtonsVisible = !this.landAtResults!;
    this.localNotificationItem.IsSurvey = true;
    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;
  }
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // scroll to top when edit pane opens
    Root.emit('new-notification-edit-item-pane', true);

    // set type id to dispaly correct component
    this.notificationTypeId = this.localNotificationItem!.NotificationTypeId;

    if (this.notificationItemsList !== undefined && this.notificationItemsList !== null && this.notificationItemsList.length > 1) {
    // check if first item in Survey list is opened in edit mode and disable Previous button
      if (this.localNotificationItem!.Id === this.notificationItemsList[0].Id) {
        this.isPreviousButtonsVisible = false;
      }
      // check if last item in Survey list is opened in edit mode and disable Next button
      if (this.localNotificationItem!.Id === this.notificationItemsList[this.notificationItemsList.length - 1].Id) {
        this.isNextButtonsVisible = false;
      }
    }
    // #region Events
    if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements) {
      Root.emit(
        'notification-Dimensions',
        this.localNotificationItem!.NotificationDimensions,
      );
    }
    Root.on('notification-edited', (notificationItem: NotificationItem) => {
      this.localNotificationItem = notificationItem;
      this.state.localNotificationItem = this.localNotificationItem;
      // Root.emit('notification-edit-pane-values', this.localNotificationItem);
    });
    Root.on('notification-is-image-included', (val: boolean) => {
      this.localNotificationItem!.IsIncludeImage = val;
    });
    // this event handles Notification type change from dialogue type dropdown when nuser is creating new sub item.
    Root.on('notification-create-dialogue-item-in-survey', (typeId: number) => {
      this.notificationTypeId = typeId;
      this.localNotificationItem!.NotificationTypeId = typeId;
      this.localNotificationItem!.NotificationType = NotificationTypeEnumParse.GetNotificationTypeEnum(typeId);
      // Jira #6509
      this.localNotificationItem!.SelectedChartTypeId = 0;
      this.localNotificationItem!.NotificationTypeDescription = NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(typeId);
      this.localNotificationItem!.NotificationOptions = [];
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect ||
         this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements ||
         this.localNotificationItem!.NotificationTypeId === NotificationEnum.SingleChoiceQuestion ||
         this.localNotificationItem!.NotificationTypeId === NotificationEnum.MultipleChoiceQuestion ||
         this.localNotificationItem!.NotificationTypeId === NotificationEnum.RankOptionItems) {
        for (let i = 0; i < this.numberofDefualtIOptions; i++) {
          const newOption = NotificationOption.createEmpty();
          newOption.Id = i;
          newOption.IsNewOption = true;
          this.localNotificationItem!.NotificationOptions.push(newOption);
        }
      }
      if (
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements
      ) {
      // default description for scaling type dialogue
        this.localNotificationItem!.Description = t('Dialogue.ScaleStatementsDefaultDescription').toString();
      } else if (
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.RankOptionItems
      ) {
      // default description for ranking type dialogue
        this.localNotificationItem!.Description = t('Dialogue.RankOptionsDefaultDescription').toString();
      } else {
        this.localNotificationItem!.Description = '';
      }
    });
    Root.on(
      'notification-create-dialogue-in-survey-image-uploaded',
      (ThumbnailUrl: string, ImgFullUrl: string) => {
        this.SetImageUrls(ThumbnailUrl, ImgFullUrl);
      });
    // if any feedback is removed for sub item under Survey
    Root.on('notification-feedback-removed', (removeFeedbacks: RemoveNotificationFeedback) => {
      this.localNotificationItem!.NotificationFeedbacks = removeFeedbacks.FeedbacksCount;
      Root.emit('new-notification-edit-item-pane');
      this.NavigatorButtonsVisible = true;
      // if no result is left after clearing last response user will be redireted to Settings tab.
      if (removeFeedbacks.FeedbacksCount === 0) {
        this.showResults = false;
      }
    });
  }
  private beforeUnmount() {
    Root.off('notification-edited');
  }
  private Cancel() {
    Root.emit('notification-survey-notification-edit-mode', false);
    Root.emit('notification-hide-edit-item-buttons', true);
  }
  private SetImageUrls(ImageThumbnailUrl: string, ImgFullUrl: string) {
    this.localNotificationItem!.ImageFullUrl = ImgFullUrl;
    this.localNotificationItem!.ImageThumbnailUrl = ImageThumbnailUrl;
  }
  private ValidateDialogueValidation() {
    // Validation check for Options under Dialogues which have options (SCQ/MCQ/PC/rank/Scale)
    if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.SingleChoiceQuestion ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.MultipleChoiceQuestion ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.RankOptionItems ||
        this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements
    ) {
      // Validation check if number of options added are less than 2.
      if (this.localNotificationItem!.NotificationOptions!.length < 2) {
        this.errors.push(
          this.$t('Dialogue.Input.MinimumOptions').toString(),
        );
      } else {
        // If options satisfy validations then trim the text and set defaukt style of textboxes.
        const emptyOptions: NotificationOption[] = [];
        this.localNotificationItem!.NotificationOptions!.forEach((item) => {
          if (item.Text.trim() === '') {
            emptyOptions.push(item);
            item.defaultStyling = 'background: #fff;border: solid #FF0000 2px;';
          }
        });

        // Validation check for empty options
        if (emptyOptions.length > 0) {
          this.errors.push(this.$t('Dialogues.SelectedOptions').toString());
        }
      } // Validation checks for dimensions.
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.ScaleStatements) {

        if (this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue <= this.localNotificationItem!.NotificationDimensions![0].NotificationDimensionValue) {
          this.errors.push(this.$t('Dialogue.HighValue').toString() + ' (' +

             this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue +
             ')' +
             this.$t('Dialogue.MustbeGraterthan').toString() + ' (' +
             this.localNotificationItem!.NotificationDimensions![0].NotificationDimensionValue + ')',
          );

        } else if (this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue > 10) {
          this.errors.push(this.$t('Dialogue.HighValue').toString() + ' (' +

             this.localNotificationItem!.NotificationDimensions![this.localNotificationItem!.NotificationDimensions!.length - 1].NotificationDimensionValue +
              ') ' +
             this.$t('Dialogue.CanNotBe').toString() + '10.');
        }
      }
      // validation check if no correct option is selected in Pick correct Dialogue.
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect) {
        const objIndex = this.localNotificationItem!.NotificationOptions!.findIndex(
          (obj: NotificationOption) => obj.IsCorrectOption === true,
        );
        if (objIndex === -1) {
          this.errors.push(this.$t('Dialogue.ChooseOption').toString());
        }
      }
    }
  }
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Clear Error List
  private ClearErrorsList() {
    this.errors = [];
  }
  private FillValidationErrors() {
    const self = this;
    this.errors.forEach((item) => {
      self.validationErrorFields.push(item);
    });
  }
  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  private async Submit(val: any) {
    val.$touch();
    this.validationErrorFields = [];
    this.ClearErrorsList();
    this.ClearMessagesList();
    this.ValidateDialogueValidation();
    const res = !await val.$validate();
    if(res) {
      if (val.localNotificationItem.Title!.$error === true) {
        this.validationErrorFields.push(this.$t('Title').toString());
        this.visibleValidationSummary = true;
        return;
      }
      if (this.errors.length) {
        this.FillValidationErrors();
        this.visibleValidationSummary = true;
        return;
      }
    }
    val.$reset();
    if (this.errors.length) {
      this.FillValidationErrors();
      this.visibleValidationSummary = true;
      return;
    }
    // chech if include image is checked
    if (!this.localNotificationItem!.IsIncludeImage) {
      this.localNotificationItem!.ImageFullUrl = '';
      this.localNotificationItem!.ImageThumbnailUrl = '';
    }
    if (this.errors.length === 0 && this.validationErrorFields.length === 0) {
      if (this.localNotificationItem!.IsNewSurveyItem) {
        this.AddItem();
      } else {
        this.EditItem();
      }
    }
  }
  private AddItem() {
    // Adding sub dialogue item under survey
    this.localNotificationItem!.IsNewSurveyItem = false;
    // check if create another checkbox is selected
    if (this.localNotificationItem!.creatAnother) {
      this.visibleValidationSummary = false;

      // set title text to display in item added Success popup
      this.addedItemTitle = JSON.parse(JSON.stringify(this.localNotificationItem!.Title));
      this.addedDialogueItemToSurvey(); // Show popup for 2.5 sec.
      setTimeout(() => {
        // emit event for create new empty information type dialogue and set it in edit pane.
        Root.emit('notification-create-another-sub-item', true);
      }, 10);
      setTimeout(() => {
        // reset notification title textbx style.
        Root.emit('notification-title-field-reset');
      }, 50);
    } else {
    // if create another was not selected
      this.AddAndUpdateSourceOftruth();
      this.ShowParentButtons();
      Root.emit('scroll-to-new-sub-dialogue-item', 'subItemsListView');
    }
  }
  private EditItem() {
    Root.emit('notification-survey-item-edited');
    this.ShowParentButtons();
  }
  private ShowParentButtons() {
    // hide sub dialogue item buttons (Close / Add (or save)) and update sub dialogue items in localnotificationItem.
    Root.emit('notification-survey-notification-edit-mode', false);
    // show parent edit pane buttons (Cancel/Publish/ delete).
    Root.emit('notification-hide-edit-item-buttons', true);
  }
  private AddAndUpdateSourceOftruth(): boolean {
    Root.emit('notification-survey-item-added', this.localNotificationItem);
    Root.emit('notification-survey-item-edited');
    return true;
  }
  private ShowNaigators(isVisible: boolean) {
    this.showResults = !isVisible;
    this.NavigatorButtonsVisible = isVisible;
  }
  private Previous() {
  // make next button enabled if it was disabled
    this.isNextButtonsVisible = true;
    // emit event to show next itm in survey
    Root.emit('notification-survey-previous-item');
  }
  private Next() {
  // make previous button enabled if it was disabled
    this.isPreviousButtonsVisible = true;
    // emit event to show previous item in survey
    Root.emit('notification-survey-next-item');
  }
  private updateSelectedItem(val: NotificationItem) {
    this.localNotificationItem = val;
    this.state.localNotificationItem = this.localNotificationItem;
  }
  // Method to copy Link to Clipboard
  private addedDialogueItemToSurvey() {
    try {
      const successful = this.AddAndUpdateSourceOftruth();
      if (successful) {
        this.showAddedMessage = true;
      } else {
        this.additemFailure();
      }
    } catch (err) {
      this.additemFailure();
    }
    if (this.showAddedMessage) {
      setTimeout(() => {
        this.showAddedMessage = false;
      }, this.itemaddedFadeoutTime);
    }
  }
  private additemFailure() {
    // if somehow failed to add sub item under survey show message.
    this.addedItemTitle = this.$t('Dialogue.ItemAddFailure').toString();
    const element: HTMLBodyElement = document.getElementById('addItemMsgDiv') as HTMLBodyElement;
    element.classList.add('alert-danger');
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(newval: NotificationItem) {
    Root.emit('notification-edit-pane-values', newval);
  }
  @Watch('selectedNotificationItem')
  private onSelectedItemChange(newval: NotificationItem, oldVal: NotificationItem) {
    const currentNotificationItem = NotificationItem.prototype.SetNotificationObject(
      newval,
    );
    const previousNotificationItem = NotificationItem.prototype.SetNotificationObject(
      oldVal,
    );
    if (
      !Common.prototype.CompareObjects(
        currentNotificationItem,
        previousNotificationItem,
      )
    ) {
      this.updateSelectedItem(newval);
    }
    this.notificationTypeId = this.localNotificationItem!.NotificationTypeId;
    this.localNotificationItem!.IsSurvey = true;
    if ( this.showResults ) {
      this.NavigatorButtonsVisible = false;
    }

    if (this.notificationItemsList !== undefined && this.notificationItemsList !== null && this.notificationItemsList.length > 0) {
    // check if first item in Survey list is reached in edit mode and disable Previous button
      if (newval.Id === this.notificationItemsList[0].Id) {
        this.isPreviousButtonsVisible = false;
      }
      // check if last item in Survey list is reached in edit mode and disable Previous button
      if (newval.Id === this.notificationItemsList[this.notificationItemsList.length - 1].Id) {
        this.isNextButtonsVisible = false;
      }
    }
  }
  private gertUniueId(): number {
    // get unique id to set for dialogue sub item as the list view is binded to Id, so ids should bu unique.
    // to avoid ( update conflict ids are same )
    let newId = 0;

    if (this.localNotificationItem!.QuestionnaireNotifications! !== undefined && this.localNotificationItem!.QuestionnaireNotifications !== null && this.localNotificationItem!.QuestionnaireNotifications.length > 0) {
      this.localNotificationItem!.QuestionnaireNotifications.forEach((item) => {
        this.uniqueIdArray.push(item.Id);
      });

      newId = -Math.abs(9999 + this.localNotificationItem!.QuestionnaireNotifications[this.localNotificationItem!.QuestionnaireNotifications.length - 1].Id);
      while (this.uniqueIdArray.includes(newId)) {
        newId--;
      }
    }
    return newId;
  }
}
export default toNative(NotificationSurveyNotificationItemEditPane);
