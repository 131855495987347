
import { Component, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import FileUpload from '@/components/Common/FileUpload.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { NotificationUserResponseOptionsItem } from '@/entities/Notification/NotificationUserResponseOptionsItem';
import NotificationUserResponseOptions from '@/components/Notification/NotificationCommon/NotificationUserResponseOptions.vue';
import NotificationFooterPane from '@/components/Notification/NotificationCommon/NotificationFooterPane.vue';
import NotificationSurveyItemsListView from '@/components/Notification/Survey/NotificationSurveyItemsListView.vue';
import NotificationSurveyNotificationItemEditPane from '@/components/Notification/Survey/NotificationSurveyNotificationItemEditPane.vue';
import Common from '@/helper/Common';
import NotificationEnum from '@/enums/NotificationEnum';
import NotificationSurveyAllResults from '@/components/Notification/NotificationResults/NotificationSurveyAllResults.vue';
import { NotificationDetailViewModel } from '@/entities/Notification/NotificationDetailViewModel';
import { Root, Store } from '@/main';

@Component({
  components: {
    FileUpload,
    NotificationUserResponseOptions,
    NotificationFooterPane,
    NotificationSurveyItemsListView,
    NotificationSurveyNotificationItemEditPane,
    NotificationSurveyAllResults,
  },
})
class NotificationSurvey extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];

  private readonly SelectedEditDialogue = 'selectedEditDialogue';

  private localNotificationItem: NotificationItem | null = null;
  private activeSubComponent = '';
  private EditNotificationItemInSurvey: NotificationItem = NotificationItem.createEmpty();
  private islandAtResults = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private readonly defaultImageWidth = 320;
  private readonly defaultImageHeight = 200;
  private subNotificationItemOldValue: NotificationItem = NotificationItem.createEmpty();
  private IsDirty = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private exportFileName = '';
  private virtualSurveySubNotificationList: NotificationItem[] = [];
  // dummy
  private showFileUploadProcess = false;

  private IsEditMode = false;
  // User Response Options
  private userResponseoptions: NotificationUserResponseOptionsItem = NotificationUserResponseOptionsItem.createEmpty();
  private moduleName = 'Notification';
  private notificationTypeId = -1;
  private isMobileView = false;
  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }
  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;
    // virtualSurveySubNotificationList is the main source of truth any item added/ edited/ deleted will be
    // saved in this array of dialogues.
    if (this.localNotificationItem.QuestionnaireNotifications! !== undefined) {

      this.virtualSurveySubNotificationList = JSON.parse(JSON.stringify(this.localNotificationItem.QuestionnaireNotifications));
    }
    this.showUserResponseOptions();
    // this.localNotificationItem.QuestionnaireNotifications! = this.localNotificationItems;
    Root.on('notification-survey-notification-edit-mode', (val: boolean) => {
      this.IsEditMode = val;
      this.showUserResponseOptions();
      if (!val) {
        this.localNotificationItem!.QuestionnaireNotifications = this.virtualSurveySubNotificationList;
      }
    });
    // event handler of showing image upload
    Root.on('notification-include-in-survey-item-file-upload', (val: boolean) => {
      this.clickOnImage(val);
    });
    Root.on('scroll-to-new-sub-dialogue-item', (id: string) => {
      this.scrollToElement(id);
    });
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // To set title input box focused when clicked in create new Survey
    if (this.localNotificationItem.Id === 0 ) {
      const element: HTMLBodyElement =  document.getElementById('surveyTitle') as HTMLBodyElement;
      if (element !== null) {
        element.focus();
      }
    }
    const self = this;
    Root.on('notification-include-in-survey-item-file-upload', (val: boolean) => {
      this.clickOnImage(val);
    });
    // saving the edits changes made by user using Edit option or Next / previous option in dialogue listing.
    Root.on('notification-edit-pane-values', (val: NotificationItem) => {
      this.EditNotificationItemInSurvey = val;

      if (this.localNotificationItem!.QuestionnaireNotifications !== null && this.localNotificationItem!.QuestionnaireNotifications !== undefined && this.localNotificationItem!.QuestionnaireNotifications.length > 0) {

        const objindex = this.localNotificationItem!.QuestionnaireNotifications.findIndex((x: NotificationItem) => x.Id === val.Id);
        if (objindex > -1) {
          this.localNotificationItem!.QuestionnaireNotifications[objindex] = val;
        }
      }
    });
    Root.on('notification-survey-item-edited', () => {
      this.virtualSurveySubNotificationList = this.localNotificationItem!.QuestionnaireNotifications!;
    });
    Root.on('notification-survey-previous-item', () => {
      this.switchToPreviousItem();
    });
    Root.on('notification-survey-next-item', () => {
      this.switchToNextItem();
    });
    Root.on('notification-survey-details', (val: NotificationItem) => {
      this.virtualSurveySubNotificationList = val.QuestionnaireNotifications!;
      self.localNotificationItem!.QuestionnaireNotifications = val.QuestionnaireNotifications;
      this.EditNotificationItemInSurvey.QuestionnaireNotifications = val.QuestionnaireNotifications;
      self.localNotificationItem!.RecipientCount = val.RecipientCount;
      self.localNotificationItem!.NotificationFeedbacks = val.NotificationFeedbacks;
    });
    Root.on('notification-create-sub-item-button-click', () => {
      this.AddNewNotificationItemItemtoSurvey(false);
    });
    Root.on('notification-create-another-sub-item', (val: boolean) => {
      if (val) {
        this.AddNewNotificationItemItemtoSurvey(true);
      }
    });
    Root.on('update-notification-details',(val: NotificationDetailViewModel)=> {
      if(this.localNotificationItem !== null && this.localNotificationItem !== undefined && this.localNotificationItem.QuestionnaireNotifications !== null && this.localNotificationItem.QuestionnaireNotifications !== undefined) {
        this.localNotificationItem.QuestionnaireNotifications.map((localVal) => {
          if(val.Id === localVal.Id) {
            localVal.RankingHighChartData = val.RankNotificationResults;
            localVal.HighChartData = val.OptionResults;
            localVal.ScalingHighChartData = val.ScaleNotificationResults;
            localVal.Wordclouds = val.Wordclouds;
            localVal.NotificationFeedbacks = val.NotificationFeedbacks;
          }
        });
      }
    });
  }
  private clickOnImage(val: boolean) {
    this.showFileUploadProcess = val;
  }
  private ShowSurveyNotificationEditDetailView() {
    return (
      this.localNotificationItem &&
      !this.showFileUploadProcess
    );
  }
  private ShowFileUploadSection() {
    return (
      this.localNotificationItem &&
      this.showFileUploadProcess
    );
  }

  private GetValidationType() {
    if (this.showFileUploadProcess === true) {
      return 'lessthan';
    }
  }
  private GetIconImageSize() {
    if (this.showFileUploadProcess === true) {
      return 154;
    }
  }
  private GetAcceptedFileTypes() {
    const acceptedFileTypes: string[] = [];
    if (this.showFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
    }
    return acceptedFileTypes;
  }
  private GetFileExtensions() {
    if (this.showFileUploadProcess === true) {
      return '.png,.jpg,.jpeg';
    }
  }
  private GetImageUrl() {
    return this.missingImage;
  }
  private GetThumbnailImageUrl() {

    return (this.EditNotificationItemInSurvey.ImageThumbnailUrl === '' || this.EditNotificationItemInSurvey.ImageThumbnailUrl === null)
      ? this.missingImage
      : this.EditNotificationItemInSurvey.ImageThumbnailUrl;
  }
  private GetfeaturedimageFullUrl() {
    if (
      this.EditNotificationItemInSurvey.ImageFullUrl === '' ||
      this.EditNotificationItemInSurvey.ImageFullUrl === null
    ) {
      this.EditNotificationItemInSurvey.FeaturedImageWidth = this.defaultImageWidth;
      this.EditNotificationItemInSurvey.FeaturedImageHeight = this.defaultImageHeight;
      return this.featureMissingImage;
    } else {
      return this.EditNotificationItemInSurvey.ImageFullUrl;
    }
  }
  private GetImageProcessHeadingTitle(): string {
    return '';
  }
  private CheckFeaturedImage() {
    return this.EditNotificationItemInSurvey.ImageFullUrl === '' ||
      this.EditNotificationItemInSurvey.ImageFullUrl === null
      ? false
      : true;
  }
  private GetValidationMessage() {
    if (this.showFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOnly';
    }
  }
  private GetImageType() {
    return '';
  }
  private SetImageIconUrl(
    imgThumbnailurl: string,
    ImageFullUrl: string,
  ) {
    if (this.showFileUploadProcess === true) {
      this.EditNotificationItemInSurvey.ImageThumbnailUrl = imgThumbnailurl;
      this.EditNotificationItemInSurvey.ImageFullUrl = ImageFullUrl;
    }
  }

  private showUserResponseOptions() {
    this.userResponseoptions.IsOnlyAllowResponseVisible = false;
    this.userResponseoptions.IsshowAnswersInPhoneVisible = false;
    this.userResponseoptions.OnlyAllowResponseValue = this.localNotificationItem!.IsRespondOnlyOnce!;
    this.userResponseoptions.ShowAnswersInPhoneValue = this.localNotificationItem!.EnableClientShowResults!;
    this.userResponseoptions.MakeUserAnonumousValue = this.localNotificationItem!.EnableResponseAnonymously!;
    if (this.localNotificationItem!.IsPublished) {
      this.userResponseoptions.MakeUserAnonumousDisabled = true;
    }
  }
  private setUserResponseOptions(event: any) {
    const newUserResponseItem: NotificationUserResponseOptionsItem = event;
    this.localNotificationItem!.IsRespondOnlyOnce = newUserResponseItem.OnlyAllowResponseValue;
    this.localNotificationItem!.EnableClientShowResults = newUserResponseItem.ShowAnswersInPhoneValue;
    this.localNotificationItem!.EnableResponseAnonymously = newUserResponseItem.MakeUserAnonumousValue;
  }
  private SetSelectedFolder(folder: string) {
    this.localNotificationItem!.Folder = folder;
  }
  private SetAccessCode(accessCode: string) {
    this.localNotificationItem!.Code = accessCode;
  }
  private SetFilter(filterId: number) {
    this.localNotificationItem!.FilterId = filterId;
    if (filterId > 0 ) {
      const objindex = this.filters!.findIndex(
        (obj: NotificationFilter) => obj.Id === filterId,
      );
      if (objindex > -1) {
        this.localNotificationItem!.Filter = this.filters![objindex].FilterName;
      }
    }
  }
  private AddNewNotificationItemItemtoSurvey(isCreateAnother: boolean) {
    Root.emit('notification-hide-edit-item-buttons', false);

    // Create New Item
    const newItem = NotificationItem.createEmpty();

    if (this.localNotificationItem!.QuestionnaireNotifications !== undefined &&
     this.localNotificationItem!.QuestionnaireNotifications.length > 0) {
      const lastItemIndex = this.localNotificationItem!.QuestionnaireNotifications.length - 1;
      newItem.SortOrder = this.localNotificationItem!.QuestionnaireNotifications[lastItemIndex].SortOrder + 1;
      newItem.Id = -Math.abs(this.localNotificationItem!.QuestionnaireNotifications[lastItemIndex].SortOrder + 9999);
      newItem.ParentQuestionnaireId = this.localNotificationItem!.Id;
    } else {
      newItem.SortOrder = 1;
      newItem.Id = -1;
      newItem.ParentQuestionnaireId = this.localNotificationItem!.Id;
    }

    newItem.NotificationTypeId = 0;
    newItem.IsNewSurveyItem = true;
    newItem.IsCreateNewItemInSurvey = true;
    if (isCreateAnother) {
      newItem.creatAnother = true;
    }
    // Open Newly created item in edit Mode
    this.EditingNotificationItemInSurvey(newItem, false);
  }
  private SetNotificationItemSurvey(event: any) {
    this.localNotificationItem!.QuestionnaireNotifications = event;
  // this.virtualSurveySubNotificationList = event;
  }
  private EditingNotificationItemInSurvey(notificationItem: any, islandAtResults: any) {
    this.EditNotificationItemInSurvey = notificationItem;
    this.subNotificationItemOldValue = JSON.parse(JSON.stringify(notificationItem));
    this.islandAtResults = islandAtResults;
    this.IsEditMode = true;
  }
  private switchToPreviousItem() {
    const objIndex = this.localNotificationItem!.QuestionnaireNotifications!.findIndex(
      (obj: NotificationItem) => obj.Id === this.EditNotificationItemInSurvey.Id,
    );
    if (objIndex !== 0 && objIndex > -1) {
      const newItem = this.localNotificationItem!.QuestionnaireNotifications![objIndex - 1];
      this.EditingNotificationItemInSurvey(newItem, false);
      setTimeout(() => {
        if (newItem.NotificationFeedbacks > 0) {
          Root.emit('redraw-chart');
        }
      }, 200);
    }
  }
  private switchToNextItem() {
    const totalItems = this.localNotificationItem!.QuestionnaireNotifications!.length - 1;
    const objIndex = this.localNotificationItem!.QuestionnaireNotifications!.findIndex(
      (obj: NotificationItem) => obj.Id === this.EditNotificationItemInSurvey.Id,
    );
    if (objIndex > -1 && objIndex < totalItems) {
      const newItem = this.localNotificationItem!.QuestionnaireNotifications![objIndex + 1];
      newItem.IsSurvey = true;
      this.EditingNotificationItemInSurvey(newItem, false);
      setTimeout(() => {
        if (newItem.NotificationFeedbacks > 0) {
          Root.emit('redraw-chart');
        }
      }, 200);
    }
  }
  private ExportSurveyResults() {
    this.getActiveEventName();
    const notificationItems: NotificationItem[] = [];
    notificationItems.push(this.localNotificationItem!);
    Root.emit('notification-export-item-selected', {
      selectedtype: '',
      segmentoptions: undefined,
      notificationOptions: notificationItems,
      exportFileName: this.exportFileName + '-' + this.localNotificationItem!.Title + '.xlsx',
    });
  }
  private updateSelectedItem(val: NotificationItem) {
    this.localNotificationItem = val;
  }
  private getActiveEventName() {
    this.exportFileName =  this.storeHelper.GetActiveEventNameFromStore();
  }
  private scrollToElement(id: string) {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 200);
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(newval: NotificationItem) {
    Root.emit('notification-edited-to-parent', newval);
  }
  @Watch('EditNotificationItemInSurvey', {deep: true})
  private onSubItemInEditModeChange(newVal: NotificationItem) {
    const currentNotificationItem = NotificationItem.prototype.SetNotificationObject(
      newVal,
    );
    const previousNotificationItem = NotificationItem.prototype.SetNotificationObject(
      this.subNotificationItemOldValue,
    );
    if (
      !Common.prototype.CompareObjects(
        currentNotificationItem,
        previousNotificationItem,
      )
    ) {
      this.IsDirty = true;
      Root.emit('notification-survey-sub-item-dirty', this.IsDirty);
    } else {
      this.IsDirty = false;
    }
  }
  @Watch('selectedNotificationItem')
  private onSelectedItemChange(newval: NotificationItem, oldVal: NotificationItem) {
    this.localNotificationItem!.QuestionnaireNotifications = [];
    const currentNotificationItem = NotificationItem.prototype.SetNotificationObject(
      newval,
    );
    const previousNotificationItem = NotificationItem.prototype.SetNotificationObject(
      oldVal,
    );
    currentNotificationItem.Folder = currentNotificationItem.Folder === null ? '' : currentNotificationItem.Folder;
    previousNotificationItem.Folder = previousNotificationItem.Folder === null ? '' : previousNotificationItem.Folder;
    currentNotificationItem.Code = currentNotificationItem.Code === null ? '' : currentNotificationItem.Code;
    previousNotificationItem.Code = previousNotificationItem.Code === null ? '' : previousNotificationItem.Code;
    if (
      !Common.prototype.CompareObjects(
        currentNotificationItem,
        previousNotificationItem,
      )
    ) {
      this.updateSelectedItem(newval);
    }
  }
}
export default toNative(NotificationSurvey);
