
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationDetailViewModel } from '@/entities/Notification/NotificationDetailViewModel';
import NotificationHighCharts from './NotificationHighCharts.vue';
import NotificationOptionTable from './NotificationOptionsTable.vue';
import NotificationResponseCounterAndReminder from './NotificationResponseCounterAndReminder.vue';
import NotificationIndividualResponses from './NotificationIndividualResponses.vue';
import NotificationTitleAndViewLiveResponses from './NotificationTitleAndViewLiveResponses.vue';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import GenerateToken from '@/components/Common/GenerateToken.vue';
import { Root } from '@/main';
@Component({
  components: {
    NotificationHighCharts,
    NotificationOptionTable,
    NotificationResponseCounterAndReminder,
    NotificationIndividualResponses,
    NotificationTitleAndViewLiveResponses,
    GenerateToken,
  },
})
class NotificationResultsDetails extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;

  private localNotificationItem: NotificationItem | null = null;
  private localNotificationLoading = false;
  public get chartTypeEnum(): typeof ChartTypeEnum {
    return ChartTypeEnum;
  }
  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem);
    Root.on('notificationDetailLoading', (loadingval: boolean) => {
      this.localNotificationLoading = loadingval;
    });
    /* in following emit we handle the details that we got from details API. The data in it is Response data
    this data includes
    1. High chart data
    2. detail of responses*/
    Root.on(
      'update-notification-details',
      (selectedNotificationItem: NotificationDetailViewModel) => {
        if (this.localNotificationItem!.Id === selectedNotificationItem.Id) {
          this.localNotificationItem!.RecipientCount = selectedNotificationItem.RecipientCount;
          this.localNotificationItem!.HighChartData = selectedNotificationItem.OptionResults;
          this.localNotificationItem!.RankingHighChartData = selectedNotificationItem.RankNotificationResults;
          this.localNotificationItem!.ScalingHighChartData = selectedNotificationItem.ScaleNotificationResults;
          this.localNotificationItem!.UserFeedback = selectedNotificationItem.UserFeedback;
          this.localNotificationItem!.Wordclouds = selectedNotificationItem.Wordclouds;
          this.localNotificationItem!.NotificationFeedbacks = selectedNotificationItem.NotificationFeedbacks;
          this.localNotificationItem!.PowerPointToken = selectedNotificationItem.PowerPointToken;
          this.localNotificationItem!.IsAnyLinkedItemWithNotification = selectedNotificationItem.IsAnyLinkedItemWithNotification;
          Root.emit('update-power-point-token',this.localNotificationItem!.PowerPointToken);
        }
      },
    );
  }
  @Watch('selectedNotificationItem')
  private OnChange(val: NotificationItem) {
    this.localNotificationItem = val;
  }
}
export default toNative(NotificationResultsDetails);
