
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import NotificationEnum from '@/enums/NotificationEnum';
import { ScaleStatementInsight } from '@/entities/Notification/NotificationResults';
@Component({})
class NotificationOptionTable extends Vue {
  @Prop() private selectedNotificationItem!: NotificationItem;
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private notificationTypeId = -1;
  private totalUsersFeedbacksCount = 0; // Number of users responses
  private scaleStatmentInsights: ScaleStatementInsight[] = [];

  private mounted() {
    this.localNotificationItem = Object.assign({},this.selectedNotificationItem);
    this.SetUsersFeedbackCountAndNotificationTypeId();
    if(this.localNotificationItem.NotificationTypeId === NotificationEnum.ScaleStatements) {
      this.SetScalingTableData();
    }
  }
  // this helps in setting and updating the value for total user feedback count and local notification type id which result in handling the empty user feedback case and to show the apt type of table respectively
  private SetUsersFeedbackCountAndNotificationTypeId() {
    if(this.localNotificationItem.UserFeedback !==undefined) {
      this.totalUsersFeedbacksCount = this.localNotificationItem.UserFeedback.length;
    }
    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;
  }
  // this helps in formatting the Scaling highchart data to show in scaling option table
  private SetScalingTableData() {
    if (this.notificationTypeId === NotificationEnum.ScaleStatements) {
      if ( this.localNotificationItem !== null && this.localNotificationItem.ScalingHighChartData !== null &&  this.localNotificationItem.ScalingHighChartData !== undefined) {
        this.scaleStatmentInsights = [];
        this.localNotificationItem.ScalingHighChartData.DataResults!.forEach((value, index) => {
          const scalerespom = ScaleStatementInsight.createEmpty();
          scalerespom.OptionText = this.localNotificationItem.ScalingHighChartData!.Options![index].Text;
          scalerespom.DataResult = value;
          this.scaleStatmentInsights.push(scalerespom);
        });
      }
    }
  }
  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }
  @Watch('selectedNotificationItem')
  private CheckChangeInSelectedNotificationItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
    this.SetUsersFeedbackCountAndNotificationTypeId();
    if(this.localNotificationItem.NotificationTypeId === NotificationEnum.ScaleStatements) {
      this.SetScalingTableData();
    }
  }
}
export default toNative(NotificationOptionTable);
