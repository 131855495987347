import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationHighCharts = _resolveComponent("NotificationHighCharts")!
  const _component_NotificationResponseCounterAndReminder = _resolveComponent("NotificationResponseCounterAndReminder")!
  const _component_NotificationOptionTable = _resolveComponent("NotificationOptionTable")!
  const _component_NotificationIndividualResponses = _resolveComponent("NotificationIndividualResponses")!
  const _component_NotificationTitleAndViewLiveResponses = _resolveComponent("NotificationTitleAndViewLiveResponses")!
  const _component_GenerateToken = _resolveComponent("GenerateToken")!

  return (_ctx.localNotificationItem !== null )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.localNotificationLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.localNotificationItem.SelectedChartTypeId !== _ctx.chartTypeEnum.TextListing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_NotificationHighCharts, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"]),
                    (!_ctx.localNotificationItem.IsSurvey)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_NotificationResponseCounterAndReminder, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationOptionTable, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationIndividualResponses, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_NotificationTitleAndViewLiveResponses, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_NotificationIndividualResponses, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                    ]),
                    (!_ctx.localNotificationItem.IsSurvey)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_NotificationResponseCounterAndReminder, { "selected-notification-item": _ctx.localNotificationItem }, null, 8, ["selected-notification-item"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.localNotificationItem.IsSurvey && !_ctx.localNotificationLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_GenerateToken, {
                "notification-id": _ctx.localNotificationItem.Id,
                "power-point-token": _ctx.localNotificationItem.PowerPointToken
              }, null, 8, ["notification-id", "power-point-token"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}