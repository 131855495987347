import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
export class NotificationFolder {
  public static createEmpty(): NotificationFolder {
    return new NotificationFolder();
  }
  constructor(
    public Id: number= 0,
    public Name: string= '',
    public IsSelected: boolean= false,
  ) {}
}
export class NotificationFolderItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): NotificationFolderItemSignalR {
    return new NotificationFolderItemSignalR();
  }

  public static GetNotificationFoldersItemSignalR(folder: NotificationFolder): NotificationFolderItemSignalR {
    const item: NotificationFolderItemSignalR = NotificationFolderItemSignalR.createEmpty();
    item.Id = folder.Id;
    item.Name = folder.Name;
    return item;
  }

  constructor(
    public Id: number = 0,
    public Name: string = '',
  ) {
    super(0, '');
  }
}

