export class NotificationFilter {
  public static createEmpty(): NotificationFilter {
    return new NotificationFilter();
  }
  constructor(
    public Id: number = 0,
    public FilterName: string = '',
    public InterestId: number = 0,
    public SegmentValueId: number = 0,
    public IsDeleted: boolean = false,
    public CreateDate: Date = new Date(),
    public ApplicationInstanceId: number = 0,
    public GroupId: number = 0,
    public IsDeactivated: boolean = false,
  ) { }
}
