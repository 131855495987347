import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c957cf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-container toggleswitch background-white border-radius-20" }
const _hoisted_2 = { class: "top-nav-bar-container display-flex" }
const _hoisted_3 = { class: "font-size-20 no-margin" }
const _hoisted_4 = {
  key: 0,
  class: "width70 text-align-end"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { id: "notificationItems" }
const _hoisted_8 = { class: "toolbar-container" }
const _hoisted_9 = { class: "new-left-side-tool-bar" }
const _hoisted_10 = { class: "no-item margin-left-10" }
const _hoisted_11 = { class: "select-all-position bold-text" }
const _hoisted_12 = { class: "right-side-tool-bar menu" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "margin-left-5"
}
const _hoisted_15 = {
  key: 1,
  class: "margin-left-5"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 0,
  class: "margin-left-5"
}
const _hoisted_18 = {
  key: 1,
  class: "margin-left-5"
}
const _hoisted_19 = { class: "inner-flex-container" }
const _hoisted_20 = { class: "left-side-container lising-overflow" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "area100" }
const _hoisted_25 = ["src"]
const _hoisted_26 = {
  key: 2,
  class: "width70 text-align-end create-new-btn-div display-flex justify-content-end"
}
const _hoisted_27 = ["src"]
const _hoisted_28 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_NotificationExportPopup = _resolveComponent("NotificationExportPopup")!
  const _component_NotificationListView = _resolveComponent("NotificationListView")!
  const _component_NotificationParentEditPane = _resolveComponent("NotificationParentEditPane")!
  const _component_NotificationBatchEditPane = _resolveComponent("NotificationBatchEditPane")!
  const _component_NotificationFolderListView = _resolveComponent("NotificationFolderListView")!
  const _component_NotificationAddItemPane = _resolveComponent("NotificationAddItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.exportPopupVisible)
      ? (_openBlock(), _createBlock(_component_NotificationExportPopup, {
          key: 1,
          "show-export-popup": _ctx.exportPopupVisible,
          "notification-items": _ctx.viewModel.NotificationList,
          "participant-segments": _ctx.viewModel.SegmentationQuestions,
          onConfirmClickExport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ConfirmExportClick($event))),
          onCancelClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CancelandHideExportPopup()))
        }, null, 8, ["show-export-popup", "notification-items", "participant-segments"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("Dialogue")), 1),
      (!_ctx.isMobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-secondary",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewNotificationSurvey && _ctx.btnCreateNewNotificationSurvey(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.surveyAdIcon }, null, 8, _hoisted_5),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.NewSurvey")), 1)
            ]),
            _createTextVNode("   "),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewNotificationItemClick && _ctx.btnCreateNewNotificationItemClick(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_6),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.CreateNewDialogue")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectAllNotificationItems) = $event)),
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAllNotificationItems]
            ]),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("SelectAll")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: "menu-item",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ManageFolders()))
          }, [
            _createElementVNode("img", {
              src: _ctx.manageIcon,
              class: "menu-item-icon"
            }, null, 8, _hoisted_13),
            (_ctx.isMobileView)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("Dialogue.ManageFolderMobile")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("Dialogue.ManageFolder")), 1))
          ]),
          _createElementVNode("div", {
            class: "menu-item margin-left-30",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ExportallDialogues()))
          }, [
            _createElementVNode("img", {
              src: _ctx.exportIcon,
              class: "menu-item-icon"
            }, null, 8, _hoisted_16),
            (_ctx.isMobileView)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("Dialogue.ExportAllMobile")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t("Dialogue.ExportAll")), 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          (_ctx.viewModel)
            ? (_openBlock(), _createBlock(_component_NotificationListView, {
                key: 0,
                "notification-items": _ctx.viewModel.NotificationList,
                folders: _ctx.viewModel.Groups,
                "select-all-items": _ctx.selectAllNotificationItems,
                "editing-notification-item": _ctx.localEditedNotificationItemItem,
                onNotificationItemsSelected: _cache[8] || (_cache[8] = ($event: any) => (_ctx.NotficationItemsSelected($event))),
                onUpdateSortingNotificationListing: _cache[9] || (_cache[9] = ($event: any) => (_ctx.UpdateSortingNotificationListing($event))),
                onChangeNotificationItem: _cache[10] || (_cache[10] = ($event: any) => (_ctx.ChangeNotificationItem($event))),
                onCreateNewDialogueClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.CreateNotification()))
              }, null, 8, ["notification-items", "folders", "select-all-items", "editing-notification-item"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-side-container", {hidden: _ctx.activeSubComponent != 'selectedNotification'}])
        }, [
          (_ctx.selectedNotificationItems.length === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_NotificationParentEditPane, {
                  ref: "EditNotificationItem",
                  "selected-notification-item": Object.assign({}, _ctx.selectedNotificationItems[0]),
                  "segment-questions": _ctx.viewModel.SegmentationQuestions,
                  folders: _ctx.viewModel.Groups,
                  filters: _ctx.viewModel.NotificationFilters,
                  "available-custom-category-folders": _ctx.localCustomCategoryFolders,
                  "available-custom-category-items": _ctx.localAllInformations,
                  "available-program-items": _ctx.localAllPrograms,
                  onNotificationItemCancelOperation: _cache[12] || (_cache[12] = ($event: any) => (_ctx.notificationItemCancelOperation($event))),
                  onNotificationItemDeleteOperation: _cache[13] || (_cache[13] = ($event: any) => (_ctx.notificationItemDeletedOperation($event)))
                }, null, 8, ["selected-notification-item", "segment-questions", "folders", "filters", "available-custom-category-folders", "available-custom-category-items", "available-program-items"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", {hidden: _ctx.activeSubComponent != _ctx.SelectedBatchNotification}])
        }, [
          (_ctx.selectedNotificationItems.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_NotificationBatchEditPane, {
                  "selected-items": _ctx.selectedNotificationItems,
                  "folder-items": _ctx.viewModel.Groups,
                  "participant-segments": _ctx.viewModel.SegmentationQuestions
                }, null, 8, ["selected-items", "folder-items", "participant-segments"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          id: "customCategoryFolders",
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != _ctx.folders }])
        }, [
          (_ctx.activeSubComponent == _ctx.folders)
            ? (_openBlock(), _createBlock(_component_NotificationFolderListView, {
                key: 0,
                "folder-items": _ctx.viewModel.Groups,
                onNotificationFolderCloseOperation: _cache[14] || (_cache[14] = ($event: any) => (_ctx.NotificationFolderItemCancelOperation()))
              }, null, 8, ["folder-items"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          id: "newNotification",
          class: _normalizeClass(["edit-panel right-inner-flex-container", {
            hidden: _ctx.activeSubComponent != _ctx.SelectedCreateNewDialogue,
          }])
        }, [
          (_ctx.createNewClicked)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_NotificationAddItemPane, {
                  "enable-ranking-dialogue": _ctx.EnableRankingDialogue,
                  "enable-scaling-dialogue": _ctx.EnableScalingDialogue,
                  onNotificationCreateNewPaneClose: _cache[15] || (_cache[15] = ($event: any) => (
                _ctx.NotificationCreateItemCancelOperation()
              ))
                }, null, 8, ["enable-ranking-dialogue", "enable-scaling-dialogue"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel no-item right-inner-flex-container", { hidden: _ctx.activeSubComponent != '' }])
        }, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("iframe", {
              class: "iframe-area",
              src: _ctx.GetIframeUrl()
            }, null, 8, _hoisted_25)
          ])
        ], 2)
      ])
    ]),
    (_ctx.isMobileView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("button", {
            class: "btn btn-secondary create-new-btn",
            onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewNotificationSurvey && _ctx.btnCreateNewNotificationSurvey(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.surveyAdIcon }, null, 8, _hoisted_27),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Dialogue.Survey")), 1)
          ]),
          _createTextVNode("   "),
          _createElementVNode("button", {
            class: "btn btn-primary create-new-btn",
            onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewNotificationItemClick && _ctx.btnCreateNewNotificationItemClick(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_28),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("CreateItem")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}