import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "spider-chart-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCharts)
      ? (_openBlock(), _createBlock(_component_highcharts, {
          key: 0,
          options: _ctx.highchartData
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}