import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toHandlers as _toHandlers, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf5146a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-top-20 margin-bottom-20" }
const _hoisted_2 = { class: "top-margin-in-fields" }
const _hoisted_3 = { class: "inline-flex100 no-margin" }
const _hoisted_4 = { class: "no-margin" }
const _hoisted_5 = { class: "inline-flex100 margin-top-5" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "div-selection-item" }
const _hoisted_8 = { class: "div-selected-icon" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "div-item-img-label" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "div-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Notification.SelectedChartTypeIdLabel")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartListModel, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.chartTypeId
        }, [
          _createElementVNode("div", _mergeProps({
            class: ["div-selection-item-container border-radius-10", [ _ctx.IsChartDisabled(item.chartTypeId) ? 'selection-disabled' : 'cursor-pointer']],
            onClick: ($event: any) => (_ctx.ChangeSelectedChartType(item.chartTypeId))
          }, _toHandlers(_ctx.IsChartDisabled(item.chartTypeId) ? {mouseover : ()=> _ctx.ShowTooltip(true),mouseleave : ()=> _ctx.ShowTooltip(false)} : {}, true)), [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (item.chartTypeId === _ctx.localNotificationItem.SelectedChartTypeId)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.greenTickIcon
                    }, null, 8, _hoisted_9))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  class: "chart-img",
                  src: item.chartIcon
                }, null, 8, _hoisted_11),
                _createElementVNode("label", {
                  class: _normalizeClass([[_ctx.IsChartDisabled(item.chartTypeId) ? 'selection-disabled' : 'cursor-pointer'], "description"])
                }, _toDisplayString(item.chartName), 3)
              ])
            ])
          ], 16, _hoisted_6)
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Notification.DialogueResult.Tooltip')), 1)
      ], 512), [
        [_vShow, _ctx.showTooltip]
      ])
    ])
  ]))
}