export class LinkableItem {
  public static createEmpty(): LinkableItem {
    return new LinkableItem();
  }
  constructor(
    public Id: number = 0,
    public IsLinked: boolean= false,
    public EntityId: number = 0,
    public EntityType: number = 0,
  ) { }
}

