const NotificationEnum = {
  InformationMessage: 0,
  SingleRespponseTextFeedback: 1,
  SingleChoiceQuestion: 2,
  MultipleChoiceQuestion: 3,
  MultipleRespponseTextFeedback: 4,
  Survey: 5,
  PickCorrect: 6,
  RankOptionItems: 7,
  ScaleStatements: 8,
  CreateNew : -1,
};

export default Object.freeze(NotificationEnum);

export class NotificationTypeDescriptionEnumParse {
  public static GetNotificationTypeDescriptionEnum(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  'Information Message';
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = 'Single Response Text Feedback';
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = 'Single Choice Question';
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = 'Multiple Choice Question';
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = 'Multiple Response Text Feedback';
      break;
    case NotificationEnum.PickCorrect:
      value = 'Pick correct answer';
      break;
    case NotificationEnum.RankOptionItems:
      value = 'Rank option items';
      break;
    case NotificationEnum.ScaleStatements:
      value = 'Scale statements';
      break;
    }
    return value;
  }
}

export class NotificationTypeEnumParse {
  public static GetNotificationTypeEnum(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  'NoAction';
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = 'TextAction';
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = 'ChoiceAction';
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = 'MultipleChoiceAction';
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = 'TextMultipleAnswersOption';
      break;
    case NotificationEnum.PickCorrect:
      value = 'CorrectAnswerChoice';
      break;
    case NotificationEnum.RankOptionItems:
      value = 'RankingAction';
      break;
    case NotificationEnum.ScaleStatements:
      value = 'ScalingAction';
      break;
    }
    return value;
  }
}
export class NotificationTypeForSurveyEnumParse {
  public static GetNotificationTypeEnum(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  'Information';
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = 'Text feedback';
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = 'Single choice option';
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = 'Multi choice option';
      break;
    case NotificationEnum.PickCorrect:
      value = 'Pick correct answer';
      break;
    case NotificationEnum.RankOptionItems:
      value = 'Rank option items';
      break;
    case NotificationEnum.ScaleStatements:
      value = 'Scaling option items';
      break;
    }
    return value;
  }
}
export class NotificationIconEnumParse {
  public static GetNotificationTypeIcon(typeId: number): string {
    let value = '';
    switch (typeId) {
    case NotificationEnum.InformationMessage:
      value =  require('@/assets/Images/information-icon.svg');
      break;
    case NotificationEnum.SingleRespponseTextFeedback:
      value = require('@/assets/Images/text-feedback.svg');
      break;
    case NotificationEnum.SingleChoiceQuestion:
      value = require('@/assets/Images/multiple-choice.svg');
      break;
    case NotificationEnum.MultipleChoiceQuestion:
      value = require('@/assets/Images/multiple-choice.svg');
      break;
    case NotificationEnum.MultipleRespponseTextFeedback:
      value = require('@/assets/Images/text-feedback.svg');
      break;
    case NotificationEnum.PickCorrect:
      value = require('@/assets/Images/pick-correct.svg');
      break;
    case NotificationEnum.RankOptionItems:
      value = require('@/assets/Images/rank-icon.svg');
      break;
    case NotificationEnum.ScaleStatements:
      value = require('@/assets/Images/scale-statement.svg');
      break;
    case NotificationEnum.Survey:
      value = require('@/assets/Images/survey-icon.svg');
      break;
    }
    return value;
  }
}

