import { NotificationOption } from './NotificationOption';
export class DialogueWordcloudData {
  public static createEmpty(): DialogueWordcloudData {
    return new DialogueWordcloudData();
  }
  constructor(
    public Name?: string[],
    public Weights?: number[],
  ) { }
}
export class OptionResult {
  public static createEmpty(): OptionResult {
    return new OptionResult();
  }
  constructor(
    public Option: string = '',
    public NumberOfVotes: number = 0,
    public Index: number = 0,
    public PercentageOfCastVotes: number = 0,
  ) { }
}
export class RankOptionResult {
  public static createEmpty(): RankOptionResult {
    return new RankOptionResult();
  }
  constructor(
    public NotificationOptionId: number = 0,
    public Text: string = '',
    public AdminWeight: number = 0,
    public SortOrder: number = 0,
    public TotalResponses: number = 0,
    public FinalRank: number = 0,
    public Score: number = 0,
    public Index: number = 0,
    public UserRanks?: UserRankingWithWeights[] | [],
  ) { }
}
export class UserRankingWithWeights {
  constructor(
    public UserRank: number = 0,
    public userWeight: number = 0,
  ) { }
}
export class ChoiceNotificationResponseSummary {
  public static createEmpty(): ChoiceNotificationResponseSummary {
    return new ChoiceNotificationResponseSummary();
  }
  constructor(
    public Results?: OptionResult[] | [],
  ) { }
}
export class DialogueScalingNotificationResponseSummary {
  public static createEmpty(): DialogueScalingNotificationResponseSummary {
    return new DialogueScalingNotificationResponseSummary();
  }
  constructor(
    public DilogueTitle: string = '',
    public DataResults?: number[] | [],
    public Options?: NotificationOption[] | [],
  ) { }
}
export class RankNotificationResponseSummary {
  public static createEmpty(): RankNotificationResponseSummary {
    return new RankNotificationResponseSummary();
  }
  constructor(
    public Results?: RankOptionResult[] | [],
  ) { }
}
export class UserFeedbackModel {
  public static createEmpty(): UserFeedbackModel {
    return new UserFeedbackModel();
  }
  constructor(
    public User: string = '',
    public UserId: string = '',
    public Value: string = '',
    public CreateTimeUtc?: Date,
    public Id: number = 0,
  ) { }
}
export class HighChartSeriesPointFormat {
  public static createEmpty(): HighChartSeriesPointFormat {
    return new HighChartSeriesPointFormat();
  }
  constructor(
    public name: string = '',
    public y: number = 0,
    public DataResult: number = 0,
  ) { }
}

export class PowerPointToken {
  public static createEmpty(): PowerPointToken {
    return new PowerPointToken();
  }
  constructor(
    public Id: number = 0,
    public Token: string = '',
    public ExpirationDate: Date = new Date(),
    public EntityId: number = 0,
  ) { }
}
export class ScaleStatementInsight {
  public static createEmpty(): ScaleStatementInsight {
    return new ScaleStatementInsight();
  }
  constructor(
    public OptionText: string = '',
    public DataResult: number = 0,
  ) { }
}
