import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';

export default class UpdateNotificationCustomeCategoryItem {
  public static createEmpty(): UpdateNotificationCustomeCategoryItem {
    return new UpdateNotificationCustomeCategoryItem();
  }
  constructor(
    public LinkableCustomCategories: CustomCategoryItem[] = [],
    public IsAnyItemLinked: boolean = false,
  ) {}
}
