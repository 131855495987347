
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import { Chart } from 'highcharts-vue';
import HighchartHelper from '@/helper/HighchartHelper';
import Highcharts from 'highcharts';
import borderRadius from 'highcharts-border-radius';
import exporting from 'highcharts/modules/exporting';
borderRadius(Highcharts);
@Component({
  components: {
    highcharts: Chart,
  },
})
class HighchartBarChart extends Vue {
  @Prop() chartData!: CommonHighchartData;
  private localChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private showCharts = false;
  private highchartData: object = {};
  private mounted() {
    this.localChartData = Object.assign({},this.chartData);
    this.SetChartData();
    if(Object.keys(this.highchartData).length > 0) {
      this.showCharts = true;
    }
  }
  private SetChartData() {
    exporting(Highcharts);
    const totalcount = this.localChartData.totalCount;
    this.highchartData = {
      chart: {
        type: HighchartHelper.BarChart,
        height: this.localChartData.ChartHeight,
        backgroundColor: HighchartHelper.ChartBackgroundColor,
        marginRight: this.localChartData.MarginRight,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: HighchartHelper.AreCreditsEnabled,
      },
      xAxis: {
        categories: this.localChartData.ChartCategories,
        lineColor: HighchartHelper.LineColor,
        labels: {
          style: {
            fontWeight: this.localChartData.FontWeight,
            fontFamily: this.localChartData.FontFamily,
            fontSize: this.localChartData.FontSize,
            color: this.localChartData.FontColor,
          },
          useHTML: true,
          formatter() {
            const self: any = this;
            const val = self.value;
            const tooltip = self.value;
            return '<span class="x-axis-label" title = "' + tooltip + '">' + val + '</span>';
          },
        },
      },
      yAxis: {
        visible: this.localChartData.ChartType === HighchartHelper.RankingChart ? false : true,
        title: {
          text: '',
        },
        lineColor: HighchartHelper.LineColor,
        lineWidth: 1,
        min: this.localChartData.MinInterval,
        max: this.localChartData.ChartType === HighchartHelper.RankingChart ? undefined : this.localChartData.MaxInterval,
        gridLineWidth: HighchartHelper.GridLineWidth,
        tickInterval: this.localChartData.TickInterval,
        labels: {
          style: {
            fontWeight: this.localChartData.FontWeight,
            fontSize: this.localChartData.FontSize,
            color: this.localChartData.FontColor,
            fontFamily: this.localChartData.FontFamily,
          },
        },
      },
      colors: this.localChartData.Colors,
      tooltip: {
        enabled: (this.localChartData.ChartType === HighchartHelper.RankingChart || this.localChartData.ChartType === HighchartHelper.ScalingChart) ? false : true,
        outside: true,
        animation: false,
        useHTML: true,
        style: {
          fontFamily: HighchartHelper.FontFamily,
          fontWeight: this.localChartData.FontWeight,
        },
        backgroundColor: HighchartHelper.tooltipBackgroundColor,
        formatter(this: Highcharts.PointLabelObject) {
          return this.point.category + ' = ' + Math.round(this.y! * totalcount /100);
        },
      },
      plotOptions: {
        bar: {
          borderRadiusTopRight: HighchartHelper.BarBorderRadius,
          borderRadiusTopLeft:HighchartHelper.BarBorderRadius,
          dataLabels: {
            enabled: this.localChartData.ChartType === HighchartHelper.RankingChart ? false : true,
            crop: false,
            overflow: 'allow',
            style: { color: this.localChartData.FontColor, fontSize: this.localChartData.FontSize },
            format: (this.localChartData.ChartType === HighchartHelper.BarChart || this.localChartData.ChartType === HighchartHelper.PieChart) ? HighchartHelper.BarDataLabelsFormat : HighchartHelper.ScalingDataLabelsFormat,
          },
          borderWidth: HighchartHelper.BorderWidth,
        },
      },
      legend: {
        enabled: HighchartHelper.AreLegendsEnabled,
      },
      exporting: HighchartHelper.Exporting,
      series: [
        {
          pointPadding: HighchartHelper.PointPadding,
          showInLegend: HighchartHelper.ShowInLegend,
          data: this.localChartData.ChartData,
          colorByPoint: HighchartHelper.ColorByPoint,
        },
      ],
    };
  }
  @Watch('chartData')
  private CheckChangeInChartData(newVal: CommonHighchartData) {
    this.localChartData = newVal;
    this.SetChartData();
  }
}
export default toNative(HighchartBarChart);
