import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "margin-top-20 margin-bottom-20" }
const _hoisted_2 = { for: "Options" }
const _hoisted_3 = { class: "color-red" }
const _hoisted_4 = { value: 0 }
const _hoisted_5 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("label", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.ChooseCorrectOption")), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Asteric")), 1)
      ]),
      _createElementVNode("select", {
        id: "selOptions",
        class: "form-control selectBox",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.OnCorrectOptionChange($event)))
      }, [
        _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t("Dialogue.PickcorrectChooseOptionDropDown")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableNotificationOptions, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: option.Id,
            selected: option.Text == _ctx.selectedNotificationOption
          }, _toDisplayString(option.Text), 9, _hoisted_5))
        }), 128))
      ], 32)
    ])
  ]))
}