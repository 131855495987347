
import { Root } from '@/main';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
@Component

class SendPushOnPublish extends Vue {
  @Prop() private isSentPublish?: boolean;
  @Prop() private isPublished?: boolean;

  private localIsSentPublish = false;
  private localIsPublished = false;

  private mounted() {
    this.localIsSentPublish = this.isSentPublish!;
    this.localIsPublished = this.isPublished!;
  }
  private change() {
    // any property change
    this.$emit('setIsSentPublishProperties', this.localIsSentPublish);
    Root.emit('setIsSentPublishProperties', this.localIsSentPublish);
  }

  @Watch('isPublished')
  private onIsPublishedChanged(){
    this.localIsPublished = this.isPublished!;
  }
}
export default toNative(SendPushOnPublish);
