
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import loading from '@/components/Common/loading.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationFolder } from '@/entities/Notification/NotificationFolder';
import { Root, Store } from '@/main';
import { reactive } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
@Component({
  components: {
    loading,
    ValidationSummary,
  },
})
class NotificationBatchChangeFolder extends Vue {
  @Prop() private folderItems?: string[];
  @Prop() private selectedItems?: NotificationItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;

  private localFolderItems: string[] = [];
  private localFolderItemObjects: NotificationFolder[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private newfolderId = 0;
  private TopLevel = '';
  private localFolderItem: NotificationFolder | null = NotificationFolder.createEmpty();

  private showCreateFolder = false;
  private newFolderName = '';
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private signalR: NotificationSignalRModel = new NotificationSignalRModel(
    Root,
    Store,
    this.$t,
  );
  private state = reactive({
    localFolderItem: NotificationFolder.createEmpty() as NotificationFolder | null,
  });

  private rules = {
    localFolderItem: {
      Name: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private mounted() {
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.localFolderItems = this.folderItems!;
    this.newfolderId = JSON.parse(JSON.stringify(this.folderItems!.length));
    this.TopLevel = this.$t('Dialogue.TopLevel').toString();

    this.localFolderItems.forEach((element, index) => {
      // Setting top level folder in listing of folders
      if (element === null) {
        const topLevelFolder = NotificationFolder.createEmpty();
        topLevelFolder.Id = index;
        topLevelFolder.Name = this.TopLevel;
        topLevelFolder.IsSelected = false;
        this.localFolderItemObjects.push(topLevelFolder);
      } else {
        const folderObjecttobeCreatedFromString = NotificationFolder.createEmpty();
        folderObjecttobeCreatedFromString.Id = index;
        folderObjecttobeCreatedFromString.Name = element;
        folderObjecttobeCreatedFromString.IsSelected = false;
        this.localFolderItemObjects.push(folderObjecttobeCreatedFromString);
      }
    });
    this.state.localFolderItem = this.localFolderItem;
    this.UncheckAllFolders();
  }
  private selectItem(item: NotificationFolder) {
    // Set folder item selected
    const objIndex = this.localFolderItemObjects.findIndex(
      (obj: NotificationFolder) => obj.Id === item.Id,
    );
    this.UncheckAllFolders();
    item.IsSelected = true;
    this.localFolderItemObjects[objIndex] = item;
  }
  private unselectItem(item: NotificationFolder) {
    // Set folder item unselected
    item.IsSelected = false;
    const objIndex = this.localFolderItemObjects.findIndex(
      (obj: NotificationFolder) => obj.Id === item.Id,
    );
    this.UncheckAllFolders();
    this.localFolderItemObjects[objIndex] = item;
  }
  private UncheckAllFolders() {
    // unselect all folder
    if (this.localFolderItemObjects) {
      this.localFolderItemObjects.forEach((element) => {
        element.IsSelected = false;
      });
    }
  }
  private ShowCreateFolder() {
    // Show create folder pane
    this.showCreateFolder = true;
  }
  private HideCreateFolder() {
    // Hide create
    this.showCreateFolder = false;
  }
  private CloseFolder() {
    this.$emit('selectBack');
  }

  // Create new Folder in Listing and Set it selected
  private async CreateNewFolder(val: any) {
    val.$touch();
    const res = !await val.$validate();
    if(res) {
      return;
    }
    this.UncheckAllFolders();
    this.localFolderItem!.Id =  this.newfolderId;
    this.localFolderItem!.IsSelected = true;
    this.localFolderItemObjects.push(this.localFolderItem!);
    this.HideCreateFolder();
    this.localFolderItem = NotificationFolder.createEmpty();
    this.newfolderId = this.newfolderId + 1;
  }
  // Save seleted folder
  private SaveFolder() {
    const objIndex = this.localFolderItemObjects.findIndex(
      (obj: NotificationFolder) => obj.IsSelected === true,
    );
    if (objIndex > -1) {
      const selectedFolder: NotificationFolder = this.localFolderItemObjects[
        objIndex
      ];
      // Get Ids of notification whose folder has to be changed
      const selectedNotificationIds: number[] = this.signalR.GetSelectedNotificationIdsToSendInJson(
        this.selectedItems!,
      );
      let folderTobeChanged = null;
      folderTobeChanged = selectedFolder.Name;
      // if folder name is top level then set folder value to 'null'
      if (folderTobeChanged === this.TopLevel) {
        folderTobeChanged = null;
      }
      const arr: string[] = [];
      arr.push(folderTobeChanged!);
      this.localSelectedBulkOperationModel.SelectedIds = selectedNotificationIds;
      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({
        notificationGroupFolder: arr,
      });
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      // START : Set SignalR Object properties
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(
            this.localSelectedBulkOperationModel,
            this.$t('UserName.You').toString(),
          ),
        },
      ).toString();
      // END : Set SignalR Object properties.

      // Calling SignalR API for performing bulk operation.
      this.signalR.PerformNotificationBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      // uncheck select all notifications check box
      Root.emit('notifications-uncheck-select-all');
      // update folders drop down after folders bulk opertion
      Root.emit('update-notification-folder-listing-dropdown', true);
      // add folder to folder list in view model if it is newly created
      if (objIndex === this.localFolderItemObjects.length - 1) {
        Root.emit('notification-new-folder-added', folderTobeChanged);
      }
    } else {
      // show validation if no folder selected but user clicks on save button
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('Folders.SelectFolder').toString());
      this.visibleValidationSummary = true;
    }
  }

}
export default toNative(NotificationBatchChangeFolder);
