
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { Chart } from 'highcharts-vue';
import loadWordcloud from 'highcharts/modules/wordcloud';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import HighchartHelper from '@/helper/HighchartHelper';
import { WordCloudData } from '@/entities/WordCloudModel';
loadWordcloud(Highcharts);

@Component({
  components: {
    highcharts: Chart,
  },
})
class HighchartWordCloud extends Vue {
  @Prop() private chartData!: WordCloudData[];
  private localChartData: WordCloudData[] = [];
  private highchartData: object = {};
  private showCharts = false;
  private mounted() {
    this.localChartData = this.chartData;
    this.SetChartData();
    this.showCharts = true;
  }
  private SetChartData() {
    exporting(Highcharts);
    this.highchartData = {
      chart: {
        type: HighchartHelper.WordCloud,
        backgroundColor: HighchartHelper.ChartBackgroundColor,
      },
      colors: HighchartHelper.Colors,
      series: [{
        data: this.localChartData,
        rotation: {
          from: HighchartHelper.WordCloudRotationFrom,
          to: HighchartHelper.WordCloudRotationTo,
          orientations: HighchartHelper.WordCloudRotationOrientation,
        },
        colorByPoint: HighchartHelper.ColorByPoint,
        minFontSize: HighchartHelper.WordCloudMinFontSize,
        maxFontSize: HighchartHelper.WordCloudMaxFontSize,
        // spiral: 'archimedean',
      }],
      title: {
        text: '',
      },
      credits: {
        enabled: HighchartHelper.AreCreditsEnabled,
      },
      exporting: HighchartHelper.Exporting,
    };
  }
  @Watch('chartData')
  private CheckChangeInData(newVal: WordCloudData[]) {
    this.localChartData = newVal;
    this.SetChartData();
  }
}
export default toNative(HighchartWordCloud);
