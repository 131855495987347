
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import NotificationEnum from '@/enums/NotificationEnum';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import NotificationResponseCounterAndReminder from './NotificationResponseCounterAndReminder.vue';
import NotificationSurveyHighCharts from './NotificationSurveyHighCharts.vue';
import NotificationIndividualResponses from './NotificationIndividualResponses.vue';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import { Root } from '@/main';
import RemoveNotificationFeedback from '@/entities/Emits/Notification/RemoveNotificationFeedback';
@Component({
  components: {
    NotificationResponseCounterAndReminder,
    NotificationSurveyHighCharts,
    NotificationIndividualResponses,
  },
})
class NotificationSurveyAllResults extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;
  private localNotificationItem: NotificationItem | null = null;
  private notificationTypeId = -1;
  private totalQuestionnarieItemCount = 0;
  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    if(this.localNotificationItem.QuestionnaireNotifications !== null && this.localNotificationItem.QuestionnaireNotifications !== undefined) {
      this.totalQuestionnarieItemCount = this.localNotificationItem.QuestionnaireNotifications.length;
    }
    // this will help in scroling to top of page
    Root.emit('new-notification-edit-item-pane', true);
    this.GetDimensions();
  }
  // this checks whether the chart type is Text listing
  private IsChartTypeTextListing(val: number) {
    return val === ChartTypeEnum.TextListing;
  }
  // this checks whether the dialogue item is information
  private IsDialogueItemInformation(val: number) {
    return val === NotificationEnum.InformationMessage;
  }
  private GetDimensions() {
    if (this.localNotificationItem!.QuestionnaireNotifications !== null && this.localNotificationItem!.QuestionnaireNotifications !== undefined) {
      this.localNotificationItem!.QuestionnaireNotifications.forEach((value) => {
        value.IsSurvey = true;
        this.notificationTypeId = value.NotificationTypeId;
      });
    }
  }
  @Watch('selectedNotificationItem',{deep: true})
  private CheckChangeInLocalItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
    let count = 0;
    if(this.localNotificationItem.QuestionnaireNotifications !== null && this.localNotificationItem.QuestionnaireNotifications !== undefined) {
      this.localNotificationItem.QuestionnaireNotifications.forEach((val)=> {
        count = val.NotificationFeedbacks > count ? val.NotificationFeedbacks : count;
      });
      this.totalQuestionnarieItemCount = this.localNotificationItem.QuestionnaireNotifications.length;
    }
    this.localNotificationItem.NotificationFeedbacks = count;
    const removeFeedbacks: RemoveNotificationFeedback = RemoveNotificationFeedback.createEmpty();
    removeFeedbacks.FeedbacksCount = count;
    removeFeedbacks.Id = this.localNotificationItem.Id;
    Root.emit('notification-feedback-removed',removeFeedbacks);
    this.GetDimensions();
  }
}
export default toNative(NotificationSurveyAllResults);
