enum ChartTypeEnum  {
  TextListing  = 1,
  WordCloud  = 2,
  BarChart  = 3,
  PieChart = 4,
  SliderChart = 5,
  SpiderChart = 6,
  RankingBarChart = 7,
}
export default Object.freeze(ChartTypeEnum);
