import { ProgramItem } from '@/entities/Program/ProgramItem';

export default class UpdateNotificationProgramItem {
  public static createEmpty(): UpdateNotificationProgramItem {
    return new UpdateNotificationProgramItem();
  }
  constructor(
    public LinkablePrograms: ProgramItem[] = [],
    public IsAnyItemLinked: boolean = false,
  ) {}
}
