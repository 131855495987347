
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { NotificationOption } from '@/entities/Notification/NotificationOption';

@Component
class NotificationCorrectOption extends Vue {
  @Prop() private notificationOptions?: NotificationOption[];

  private availableNotificationOptions: NotificationOption[] = [];
  private selectedNotificationOption = '';
  private mounted() {
    this.availableNotificationOptions = Object.assign(
      {},
      this.notificationOptions,
    );

    if (this.availableNotificationOptions.length > 0) {
      this.bindOptions(this.availableNotificationOptions);
    }
  }
  // bind all options of PC dialogue to dropdown of pick correct
  private bindOptions(options: NotificationOption[]) {
    const self = this;
    self.availableNotificationOptions = [];
    options.forEach((value) => {
      if (value.IsCorrectOption) {
        // to select the correct option in drop down in edit case
        this.selectedNotificationOption = value.Text;
      }
      self.availableNotificationOptions.push(value);
    });
  }
  // handles dropdown changes of pick correct choice
  private OnCorrectOptionChange(event: any) {
    const notificationOptionId = Number(event.target.value);
    // emitting event to set correct option on selection from drop down of correct options
    this.$emit('notification-correct-option-selected', notificationOptionId);
  }
  @Watch('notificationOptions', {deep: true})
  private onItemPropertyChange(
    val: NotificationOption[],
  ) {
    this.availableNotificationOptions = val;
    this.bindOptions(this.availableNotificationOptions);
    if (this.selectedNotificationOption === '') {
      this.selectedNotificationOption = this.$t('Dialogue.PickcorrectChooseOptionDropDown').toString();
    }
  }
}
export default toNative(NotificationCorrectOption);
