import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e236122"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right-container-margin" }
const _hoisted_2 = {
  key: 0,
  class: "checkbox-container description"
}
const _hoisted_3 = { class: "checkbox-text" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "checkbox-container description"
}
const _hoisted_7 = { class: "checkbox-text" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 3 }
const _hoisted_10 = {
  key: 4,
  class: "checkbox-container description"
}
const _hoisted_11 = { class: "checkbox-text" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 5 }
const _hoisted_14 = {
  key: 6,
  class: "checkbox-container description"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "checkbox-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localUserResponseOptions.IsOnlyAllowResponseVisible)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localUserResponseOptions.OnlyAllowResponseValue) = $event)),
            type: "checkbox",
            name: "onlyAllowResponseOnce",
            class: "checkbox",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change()))
          }, null, 544), [
            [_vModelCheckbox, _ctx.localUserResponseOptions.OnlyAllowResponseValue]
          ]),
          _createElementVNode("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.OnlyAllowResponseOnce")) + " ", 1),
            (_ctx.localUserResponseOptions.IsShowCorrectIncorrectFeedback)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "fa fa-info-circle",
                  "aria-hidden": "true",
                  onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetInformationString(true, 'responseonce'))),
                  onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.GetInformationString(false, 'responseonce')))
                }, null, 32))
              : _createCommentVNode("", true)
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "notification-options-responseonce tooltip-prop",
            innerHTML: _ctx.$t('PickCorrectToolTipOther')
          }, null, 8, _hoisted_4), [
            [_vShow, _ctx.responseOnceToolTip]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.IsOnlyAllowResponseVisible)
      ? (_openBlock(), _createElementBlock("br", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.IsshowAnswersInPhoneVisible)
      ? (_openBlock(), _createElementBlock("label", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localUserResponseOptions.ShowAnswersInPhoneValue) = $event)),
            type: "checkbox",
            name: "showAnswersInPhone",
            class: "checkbox",
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.change()))
          }, null, 544), [
            [_vModelCheckbox, _ctx.localUserResponseOptions.ShowAnswersInPhoneValue]
          ]),
          _createElementVNode("span", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.ShowAnswersInPhone")) + " ", 1),
            (_ctx.localUserResponseOptions.IsShowCorrectIncorrectFeedback)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "fa fa-info-circle",
                  "aria-hidden": "true",
                  onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.GetInformationString(true, 'showanswers'))),
                  onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.GetInformationString(false, 'showanswers')))
                }, null, 32))
              : _createCommentVNode("", true)
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "notification-options-answer tooltip-prop",
            innerHTML: _ctx.$t('PickCorrectToolTipOther')
          }, null, 8, _hoisted_8), [
            [_vShow, _ctx.showAnswersToolTip]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.IsShowCorrectIncorrectFeedback)
      ? (_openBlock(), _createElementBlock("br", _hoisted_9))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.IsShowCorrectIncorrectFeedback)
      ? (_openBlock(), _createElementBlock("label", _hoisted_10, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localUserResponseOptions.ShowCorrectFeedbackToClients) = $event)),
            type: "checkbox",
            name: "makeUserAnonumous",
            class: "checkbox",
            onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.change()))
          }, null, 544), [
            [_vModelCheckbox, _ctx.localUserResponseOptions.ShowCorrectFeedbackToClients]
          ]),
          _createElementVNode("span", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.ShowFeedback")) + " ", 1),
            _createElementVNode("i", {
              class: "fa fa-info-circle",
              "aria-hidden": "true",
              onMouseover: _cache[10] || (_cache[10] = ($event: any) => (_ctx.GetInformationString(true, 'pickcorrect'))),
              onMouseleave: _cache[11] || (_cache[11] = ($event: any) => (_ctx.GetInformationString(false, 'pickcorrect')))
            }, null, 32)
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "notification-options tooltip-prop",
            innerHTML: _ctx.$t('PickCorrectToolTip')
          }, null, 8, _hoisted_12), [
            [_vShow, _ctx.pickCorrectToolTip]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.IsshowAnswersInPhoneVisible)
      ? (_openBlock(), _createElementBlock("br", _hoisted_13))
      : _createCommentVNode("", true),
    (_ctx.localUserResponseOptions.ShowMakeUserAnonumous)
      ? (_openBlock(), _createElementBlock("label", _hoisted_14, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localUserResponseOptions.MakeUserAnonumousValue) = $event)),
            type: "checkbox",
            name: "makeUserAnonumous",
            class: "checkbox",
            disabled: _ctx.localUserResponseOptions.MakeUserAnonumousDisabled,
            onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.change()))
          }, null, 40, _hoisted_15), [
            [_vModelCheckbox, _ctx.localUserResponseOptions.MakeUserAnonumousValue]
          ]),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("Dialogue.MakeUserAnonymous")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}