import { NotificationItem } from '@/entities/Notification/NotificationItem';
export class NotificationOption {
  public static createEmpty(): NotificationOption {
    return new NotificationOption();
  }
  constructor(
    public Id: number = 0,
    public Text: string = '',
    public Notification?: NotificationItem[] | [] ,
    public IsCorrectOption: boolean= false,
    public NotificationCorrectOptionHash: string = '',
    public SortOrder: number = 0,
    public OptionId: string = '',
    public defaultStyling: string= 'background-color:#ffffff;border-color:#ccc',
    public IsNewOption: boolean = false,
  ) { }
}
