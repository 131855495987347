export class NotificationUserResponseOptionsItem {
  public static createEmpty(): NotificationUserResponseOptionsItem {
    return new NotificationUserResponseOptionsItem();
  }
  constructor(
    public IsOnlyAllowResponseVisible: boolean = true,
    public IsshowAnswersInPhoneVisible: boolean = true,
    public OnlyAllowResponseValue: boolean = false,
    public ShowAnswersInPhoneValue: boolean = false,
    public MakeUserAnonumousValue: boolean = false,
    public MakeUserAnonumousDisabled: boolean = false,
    public IsShowCorrectIncorrectFeedback: boolean = false,
    public ShowCorrectFeedbackToClients: boolean = false,
    public ShowMakeUserAnonumous: boolean = true,
  ) {}
}
